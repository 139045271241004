<template>
    <section class="h-min-100vh d-flex flex-column">
        <div class="container my-auto">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="row gy-5">
                        <div class="col-lg-6 text-center">
                            <img src="@/assets/images/404-not-found.png" width="300" alt="Not found image">
                        </div>
                        <div class="col-lg-6 text-center my-md-auto">
                            <h1 class="display-6 fw-bold">
                                Oops!
                            </h1>
                            <p>
                                Sorry, something went wrong. Please come back to the homepage while we fixing the
                                problem.
                            </p>
                            <router-link :to="{ name: 'home' }" class="btn btn-blue-dark py-3 px-5">
                                BACK TO HOME PAGE
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>