<template>
  <div class="container-fluid p-3">
    <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :pagination="{
      clickable: true
    }" :breakpoints="{
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 2,
      },
    }" :modules="modules" class="mySwiper p-3 pt-5" id="reviewsCard">
      <swiper-slide class="h-auto" v-for="review in websiteStore.reviewsCollection.body.feeds" :key="review.feedId">
        <div class="col h-100 pt-5">
          <div class="row h-auto justify-content-center gy-2">
            <div class="col-10">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <img :src="review.postUserPicture" alt="" class="rounded-circle profile-pic">
                  <!-- <img src="../../assets/images/backgrounds/profile-pic.png" alt="" class="rounded-circle w-25"> -->
                </div>
                <div class="col-12 pt-4 pb-3">
                  <h5 class="card-title fw-bold m-0">{{ review.postAuthorName }}</h5>
                </div>
                <div class="col-12 d-flex justify-content-center pb-4">
                  <img src="../../assets/icons/stars.svg" alt="" class="stars">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-5 pt-0">
            <div class="row">
              <div class="col">
                <p class="card-text">{{ review.postContent }}</p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="container-fluid px-5 pb-5 d-none d-lg-block">
        <div class="row justify-content-between gallery-btn">
            <div class="col px-4 d-flex" @click="previousSlide()">
                <img src="../../assets/icons/arrow_back.svg" alt=""  class="pe-2"> Previous
            </div> 
            <div class="col text-end px-4" @click="nextSlide()">
                Next<img src="../../assets/icons/arrow_forward.svg" alt=""  class="ps-2">
            </div>
        </div>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper';
import { useStore } from '@/store/landing';

export default {
 
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
      websiteStore: useStore()
    };
  },
  methods: {
        nextSlide() {
            const swiper = document.querySelector('#reviewsCard').swiper;
            // Now you can use all slider methods like
            swiper.slideNext();
        },
        previousSlide() {
            const swiper = document.querySelector('#reviewsCard').swiper;
            // Now you can use all slider methods like
            swiper.slidePrev();
        }
    },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-fluid {
  color: black !important;
}

.swiper-slide {
  display: grid !important;
  justify-items: center !important;
  box-shadow: 0px 2px 43px -31px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 2px 43px -31px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 2px 43px -31px rgba(0,0,0,0.75);
  border: none;
}

.container p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.profile-pic{
  width: 80px!important;
}
.stars{
  width: 130px!important;
}

.card-text {
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 4; 
   -webkit-box-orient: vertical;
}

#reviewsCard {
  padding-bottom: 40px !important;
}


</style>