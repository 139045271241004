import Swal from 'sweetalert2';

//Defaults de los ajustes de Swal
const settings = {
    showConfirmButton: false,
    timer: 5000,
    backdrop: `rgba(0,101,129,0.7)`,
}

const Swal2 = Swal.mixin(settings);


export {
    Swal2
}