<template>
    <div class="row pe-3 ps-3 pt-3 text-center verify-email" v-show="tab == 1">
        <p class="title">
            Por favor verifica tu correo
        </p>
        <p>
            ¡Ya casi estás ahí! Envíamos un correo a  {{ form.email }}.
        </p>
        <p>
            Sólo tienes que dar click al enlace que
            recibiste por correo para obtener
            acceso completo a nuestras ofertas
            VIP. Sí no lo ves, tal vez pueda estar
            en tu carpeta de spam.
        </p>
        <p class="mb-1">
            ¿No recibiste el correo?
        </p>
        <button type="button" class="btn btn-blue-dark p-3 text-uppercase" @click="tab = 0">
            Re-enviar correo
        </button>
        <p class="pt-3">
            ¿Necesitas ayuda? Contacta a uno de
            nuestros expertos.
        </p>
        <h5>
            <span class="material-icons-outlined pe-3"> call </span><b>{{ websiteStore.getPhone }}</b>
        </h5>
    </div>
    <div class="row p-2" v-show="tab == 0">
        <form class="request-form" @submit.prevent="submitForm" novalidate>
            <p>
                Regístrate para obtener tu acceso VIP
            </p>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Nombre" v-model="form.firstName" required />
                <div class="invalid-feedback">
                    Ingresa un nombre
                </div>
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Apellido" v-model="form.lastName" required />
                <div class="invalid-feedback">
                    Ingresa un apellido
                </div>
            </div>
            <div class="mb-3">
                <input type="email" class="form-control" placeholder="Correo" v-model="form.email" required />
                <div class="invalid-feedback">
                    Ingresa un email
                </div>
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Teléfono" minlength="10" maxlength="10"
                       v-model="form.Number" required />
                <div class="invalid-feedback">
                    Ingresa un tel&eacute;fono
                </div>
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Hora para contactarte" v-model="form.timeToReach"
                       required />
                <div class="invalid-feedback">
                    Ingresa una hora de contacto
                </div>
            </div>
            <button type="submit" class="btn btn-blue-dark p-3 w-100" data-action='submit'>
                Enviar
            </button>
        </form>
        <p class="text-center pt-3">
            <span class="log-in-lbl" @click="displayLoginForm"><u>¿Ya te has registrado antes?</u></span>
        </p>
        <form v-if="logInForm == true" @submit.prevent="logIn">
            <div class="input-group">
                <input type="email" class="form-control" placeholder="Ingresa tu correo" aria-label="Email"
                       aria-describedby="button-addon2" required v-model="omniSearch">
                <button class="btn btn-blue-dark" type="submit">Acceder</button>
            </div>
        </form>
    </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import axios from 'axios';
    import { registerLead } from '@/api/eplat';
    import { useWebsiteStore } from '@/store/website';

    export default {
        setup() {
            const websiteStore = useWebsiteStore();

            return { websiteStore };
        },
        data() {
            return {
                form: {
                    webhookID: "b3cd0544-63e8-2872-2d4d-20ac86736f7f",
                    firstName: "",
                    lastName: "",
                    email: "",
                    number: "",
                    timeToReach: "",
                    tags: "#vip"
                },
                omniSearch: "",
                logInForm: false,
                tab: 0,
                myModal: "",

            }
        },
        methods: {
            hideModal() {
                let modal = document.querySelector('#vipModal');
                //eslint-disable-next-line
                const modalInstance = bootstrap.Modal.getInstance(modal);
                modalInstance.hide();
            },
            displayLoginForm() {
                this.logInForm = true
            },
            async logIn() {
                this.loadingMessage();
                let self = this
                await axios.get("/api/VipLead/Search?omniSearch=" + this.omniSearch)
                    .then(({ data }) => {
                        if (Array.isArray(data) && data.length > 0) {
                            this.websiteStore.setLeadID(data[0].leadID);
                            /* console.log("Es VIP");*/
                            self.redirectToVip();

                        } else {
                            self.errorMessageUser();
                            /* console.log("NO es");*/
                            /*console.log(response.data.length)*/
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                Swal.close();
            },
            async submitForm({ target }) {
                
                if (target.checkValidity()) {
                    this.loadingMessage();
                    await registerLead(this.form)
                        .then(() => {
                            this.tab = 1
                        })
                        .catch((error) => {
                            console.log(error);
                            this.errorMessage()
                            this.tab = 0
                        }).finally(() => {
                            //Perform action in always
                        });

                    Swal.close();
                } else {
                    target.classList.add('was-validated');
                }
            },
            errorMessage() {
                return Swal.fire({
                    icon: "warning",
                    title: "Oops",
                    text: "Ocurrió un error inesperado, por favor vuelve a intentarlo.",
                    iconColor: "#ffd700",
                    showConfirmButton: false,
                    timer: 5000,
                    backdrop: `rgba(0,101,129,0.7)`,
                });
            },
            loadingMessage() {
                this.loading = Swal.mixin({
                    customClass: {
                        loader: 'spinner',
                    }
                })
                return this.loading.fire({
                    text: "Enviando",
                    showConfirmButton: false,
                    iconColor: "#0c9491",
                    backdrop: `rgba(0,101,129,0.7)`,
                    width: 160,
                    timerProgressBar: true,
                    // timer: 3000,
                    didOpen: () => {
                        this.loading.showLoading();
                    },
                });
            },
            alertMessage() {
                this.tab = 3
                return Swal.fire({
                    icon: "success",
                    title: "Gracias, esta todo listo.",
                    text: "Un agente se contactara pronto.",
                    color: "#0C9491",
                    showConfirmButton: false,
                    timer: 5000,
                    iconColor: "#0C9491",
                    backdrop: `rgba(0,101,129,0.7)`,
                });
            },
            redirectToVip() {
                //this.myModal = new Modal(document.getElementById('vipModal'));
                //this.myModal.dispose();
                this.omniSearch = ""
                Swal.close()

                this.hideModal();

                this.$router.push({ name: 'vip-es' })
            }
        }
    }
</script>

<style scoped>
    .banner-redeem {
        background-image: url("@/assets/images/layout/forms-banners/redeem-banner.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .verify-email {
        font: normal normal normal 12px/18px Poppins;
    }

    .form-control {
        border: 1px solid #0c9491;
    }

    ::placeholder {
        font-size: 14px;
        padding-left: 0px;
    }

    textarea {
        resize: none;
    }

    p.title {
        font-size: 16px;
        font: normal normal bold 16px/24px Poppins;
        text-align: center;
    }

    .sub-request {
        background: transparent linear-gradient(90deg, #0c9491 0%, #3a2050 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        font: normal normal 12px/12px Arial;
    }

        .sub-request:hover {
            background: transparent linear-gradient(90deg, #3a2050 0%, #0c9491 100%) 0% 0% no-repeat padding-box;
            border-radius: 4px;
            opacity: 1;
        }

    h5 {
        color: #d50758;
    }

    @media (max-width: 991px) {
        .request-form {
            padding: 30px;
        }
    }

    .log-in-lbl {
        color: #d50758;
        cursor: pointer;
    }
</style>