<template>
    <!-- Button trigger modal -->
    <!--<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalBanner">
        Launch demo modal
    </button>-->

    <!-- Modal -->
    <div class="modal fade" id="modalBannerStart" tabindex="-1" aria-labelledby="modalBannerLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="text-end">
                    <button type="button" class="btn-close p-2 position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0 d-none d-lg-block">
                    <a href= "tel:8887211003">
                        <img :src="banner[0].imageDesktop" alt="" class="img-fluid" />
                    </a>
                </div>
                <div class="modal-body p-0 d-lg-none">
                    <a href= "tel:8887211003">
                        <img :src="banner[0].imageMobile" alt="" class="img-fluid" />
                    </a>
                </div>
            </div>
        </div>
    </div>
     <!-- <div class="modal fade" id="modalBannerCyber" tabindex="-1" aria-labelledby="modalBannerLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="text-end">
                    <button type="button" class="btn-close p-2 position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0 d-none d-lg-block">
                    <a :href=  "'tel:' + websiteStore.getPhone">
                        <img :src="banner[1].imageDesktop" alt="" class="img-fluid" />
                    </a>
                </div>
                <div class="modal-body p-0 d-lg-none">
                    <a :href="'tel:' + websiteStore.getPhone">
                        <img :src="banner[1].imageMobile" alt="" class="img-fluid" />
                    </a>
                </div>
            </div>
        </div>
    </div>  -->
</template>

<script>
    import { useWebsiteStore } from '@/store/website';


    export default {
        setup() {
            const websiteStore = useWebsiteStore();

            return {
                websiteStore,
            }
        },
        data() {
            return {
                banner: this.websiteStore.banners,
                now: new Date()
            }
        },
        methods: {
            showModal() {
                let bannerModal = document.querySelector('#modalBannerStart');
                console.log(bannerModal)
                //eslint-disable-next-line
                const bannerModalInstance = new bootstrap.Modal(bannerModal);
                bannerModalInstance.show();
            },
             showSecondModal() {
                 let bannerModal = document.querySelector('#modalBannerCyber');
                 console.log(bannerModal)
                 //eslint-disable-next-line
                 const bannerModalInstance = new bootstrap.Modal(bannerModal);
                 bannerModalInstance.show();
             }
            },
        mounted() {
            if (this.banner[0].fromDate < this.now && this.banner[0].toDate > this.now) {
                this.showModal();
            }
            // if (this.banner[1].fromDate < this.now && this.banner[1].toDate > this.now) {
            //     this.showSecondModal();
            // }
        }
        }

</script>

<style scoped>
    .btn-close {
        opacity: 1!important;
        z-index: 1!important;
        background-color: white;
    }
</style>
