import { createPinia } from 'pinia';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueCountdown from '@chenfengyuan/vue-countdown';
import TheRandomIcon from './components/TheRandomIcon.vue';

import "@/assets/site.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const pinia = createPinia()

createApp(App).use(router).use(pinia).component("TheRandomIcon",TheRandomIcon).mount('#app')