<template>
    <div class="d-flex">
        <button @click="swiper.slidePrev()" class="col-auto d-none d-lg-block bg-transparent border-0 px-4">
            <span class="material-icons-outlined">
                arrow_back_ios
            </span>
        </button>
        <swiper :pagination="swiperOptions.pagination" :slides-per-view="swiperOptions.slidesPerView"
            :space-between="swiperOptions.spaceBetween" :modules="swiperOptions.modules"
            :breakpoints="swiperOptions.breakpoints" class="mySwiper flex-fill" ref="swiper" @swiper="onLoadSwiper">
            <swiper-slide v-for="(destination, d) in destinations" :key="d">
                <weather-card :coordenates="destination" />
            </swiper-slide>
        </swiper>
        <button @click="swiper.slideNext()" class="col-auto d-none d-lg-block bg-transparent border-0 px-4">
            <span class="material-icons-outlined">
                arrow_forward_ios
            </span>
        </button>
    </div>
</template>

<script >
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import WeatherCard from '@/components/WeatherCard.vue';
import { useWebsiteStore } from '@/store/website';

export default {
    setup() {
        const websiteStore = useWebsiteStore();

        return { websiteStore };
    },
    data() {
        return {
            destinations: this.websiteStore.destinations.map(({ physical }) => physical),
            // weatherUrls: store.weatherWidget,
            swiperOptions: {
                pagination: {
                    dynamicBullets: true,
                },
                slidesPerView: 1,
                spaceBetween: 10,
                modules: [Pagination, Navigation],
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 16
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 4,
                    }
                }
            },
            swiper: null,
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        WeatherCard
    },
    methods: {
        onLoadSwiper(instance) {
            this.swiper = instance;
        }
    },
    computed: {

    },
    mounted() {
    },

}
</script>

<style scoped>
.description {
    text-transform: uppercase;
    margin-top: -20px;
}

.destination {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
}

.degrees {
    font-size: 50px;
    margin-bottom: 0px;
}

.details-box {
    background-color: #c4c4c466;
    ;
    display: flex;
    font-size: 12px;
    line-height: 25px;
}

.weather-icon {
    position: relative;
    bottom: 50px;
}

.lbl {
    color: #0C9491;
}

.swiper-slide {
    height: auto !important;
    ;
}

@media(min-width: 768px) {
    .container {
        color: #4E4E4F;
    }
}

.swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}
</style>