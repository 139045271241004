<template>
<div class="container-fluid pt-5">
    <div class="row pt-lg-5">
        <div class="col pb-5 text-center">
          <div class="row">
            <h5 class="p-2 d-lg-none">Reviews</h5>
            <div class="col-12 col-lg-4 align-items-center d-lg-flex  justify-content-end">
                <img src="../../assets/images/phone-frame.png" alt="" class="phone-frame position-absolute">
                <video controls class="reel-video tagembed-container p-2 review-video " 
                style="height: 503px;" :src="websiteStore.selectedVideo"  id="videoReview"></video>
            </div>
            <div class="col-12 col-lg-8">
                <the-video-gallery/>
            </div>
          </div>
        </div>
        <div class="col-12 pt-0">
            <reviews-card />
        </div>
    </div>
</div>


</template>

<script>
import { useStore } from '@/store/landing';
import ReviewsCard from "@/components/landing-page/TheReviewsCard.vue"
import TheVideoGallery from '@/components/TheVideoGallery.vue';

export default {
    data() {
        return {
           
        }
    },
    setup() {
        return {
            websiteStore: useStore()
        }
    },
components:{
    ReviewsCard,
    TheVideoGallery
},
 mounted() {
   
 }
}
</script>

<style scoped>
.phone-frame {
    width: 290px;
    z-index: 0;
    height: 503px!important;
}
.review-video {
    border-radius: 38px!important;
    height: 502px;
    width: 289px;
}


/* @media (min-width: 992px) { 
    .phone-frame {
        width: 371px;
    z-index: -1;
    height: 645px!important;
}
.review-video {
    border-radius: 49px!important;
    height: 643px!important;
    width: 372px!important;
}
 } */


 @media (min-width: 1200px) { 
    .phone-frame {
        width: 371px;
    z-index: 0;
    height: 645px!important;
}
.review-video {
    border-radius: 49px!important;
    height: 643px!important;
    width: 372px!important;
    z-index: 3;
}
  }


</style>