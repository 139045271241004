<template>
    <div class="container-fluid amenities-bg p-0">
        <div class="container-fluid p-0 bg-shadow">
            <div class="row justify-content-around px-2 py-5 align-items-center">
                <div class="col-12 col-lg-4 pb-5 pb-lg-0 lh-sm">
                    <h3 class="text-white">Riviera Maya - Cancun</h3>
                    <span class="h64">Tropical Paradise For Two</span>
                    <h3 class="price-txt">$499 USD per couple
                        5 Days & 4 Nights</h3>
                    <h4 class="text-white">Regular Price: <span class="text-decoration-line-through"> $1,679</span>
                    </h4>
                </div>
                <div class="col-12 col-lg-4 pt-5 pt-lg-0">
                    <p class="fs-5 text-white pt-5">Home to your perfect vacation, where Mexico's exquisite beauty meets
                        true luxury. From gigantic pools, from boutique shopping, multiple world-class restaurants,
                        beach
                        games, sports, a gym, and activities for the whole family.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid highlights-list bg-clouds">
        <div class="container p-lg-5 ">
            <div class="row gy-4">
                <h3 class="fw-bold pt-5">Highlights</h3>
                <div class="col-12 col-lg-4 mt-0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><img src="../../assets/icons/amenities/pool.png" alt="">
                            <span></span>Multiple Pools & Beach Access
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/lunch_dining.png" alt="">
                            <span></span>Dining Options at 15 Resort Restaurants
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/wine_bar.png" alt="">
                            <span></span>Wine Bar & Pool Bar
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/local_activity.png" alt="">
                            <span></span>Nightly Entertainment
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/beach_access.png" alt="">
                            <span></span>Enjoy Massage and Spa Luxuries
                        </li>
                        <li class="list-group-item p-0"></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-4 mt-0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><img src="../../assets/icons/amenities/cleaning_services.png" alt="">
                            <span></span>Pool & Beach Wait Service
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/liquor.png" alt="">
                            <span></span>Oceanfront Bars & Venues
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/airport_shuttle.png" alt="">
                            <span></span>Free Shuttle to and from the Airport
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/room_service.png" alt="">
                            <span></span>Room service
                        </li>
                        <li class="list-group-item p-0"></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-4 mt-0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><img src="../../assets/icons/amenities/local_laundry_service.png"
                                alt="">
                            <span></span>Laundry/Valet Service
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/wifi.png" alt="">
                            <span></span>Wi-fi
                            throughout the property
                        </li>
                        <li class="list-group-item"><img src="../../assets/icons/amenities/nest_clock_farsight_analog.png"
                                alt=""> <span></span>24-hour front desk</li>
                        <li class="list-group-item p-0"></li>
                    </ul>
                </div>
                <!-- <div class="row px-4 ">
                    <div class="col border border-black">
                        <div class="row justify-content-center">
                            <div class="col-6 col-lg-6  p-3 rounded text-end d-flex align-items-center">
                                <img src="../../assets/icons/amenities/lightbulb_circle.svg" alt=""> <span class="fw-bolder fs-4">Trip Tip!</span>
                            </div>
                            <div class="col-6 col-lg-6 p-3 rounded d-flex align-items-center">
                                <span class="d-inline-block d-lg-flex">Save this deal now and book dates later. You'll have up to 18 months to travel! </span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row p-2 p-lg-0 ">
                <div class="col pb-5 pt-lg-4">
                    <div class="row border border-black d-flex align-items-center justify-content-center p-2 p-lg-3">
                        <div class="col-lg-4 d-lg-flex justify-content-end align-items-center pb-2 pb-lg-0">
                            <img src="../../assets/icons/amenities/lightbulb_circle.svg" alt="" class="pe-1"> <span
                                class="fw-bolder fs-4">Trip Tip!</span>
                        </div>
                        <div class="col-lg-8">
                            <span class="d-inline-block d-lg-flex">Save this deal now and book dates later. You'll have
                                up
                                to 18 months to travel! </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid amenities-bg-03 "> 
        <div class="container">
            <div class="row justify-content-around">
                <div class="col-12 mt-5 col-lg-6 lh-base">
                    <span class="h64 green-color-text">Cancun</span>
                    <h3 class="text-black pb-5 pb-lg-0 fw-bold">Getaway for 5 Days & 4 Nights</h3>
                </div>
                <div class="col-12 pt-5 col-lg-4">
                    <p class="fs-5 text-black m-0 pb-5 mb-5">
                        Experience the ultimate vacation at our Luxury Mexico Resort. Dive into one of our three
                        refreshing
                        pools, enjoy our non-motorized water sports equipment, or walk just minutes to feel the sand
                        between
                        your toes at one of Cancun's most beautiful beaches! Our all-inclusive vacation experience
                        offers
                        the height of luxury without any of the hassle of planning dinners and activities for just $499
                        Per
                        Couple!
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.amenities-bg {
    background-image: url(../../assets/images/landing/backgrounds/bg-amenities.jpeg);
    background-size: cover;
}

.amenities-bg-03 {
    background-image: url(/src/assets/images/landing/backgrounds/bg-amenities-03.png);
    background-size: cover;
    background-position-x: 50%;
    padding-top: 270px;
}

.bg-shadow {
    background-color: rgba(0, 0, 0, .4) !important;
    padding: 5% !important;
}

.h64 {
    font-size: 64px;
    color: #ffff;
    font-weight: 900;
}

.price-txt {
    background-color: #d50758;
    color: #ffff;
}

.list-group {
    --bs-list-group-item-padding-y: 1rem;
}

.list-group-item {
    border-style: dashed;
    padding-left: 0px;
}

h3 {
    color: #0c9491;
}

.green-color-text {
    color: #0c9491;
}

.highlights-list {
    background-color: #dcf3fb;
}

.bg-clouds {
    background-image: url(/src/assets/images/landing/backgrounds/clouds.png) !important;
    background-size: contain;
}

.list-group-item {
    background-color: transparent;
}

@media (min-width: 768px) {
    .amenities-bg-03 {
        padding-top: 20%;
    }
}

/* @media (min-width: 992px) {
    .amenities-bg-03 {
        background-image: url(/src/assets/images/backgrounds/bg-amenities-03.png);
    background-size: cover;
    background-position: 50% -30px;
    }
} */
</style>