<template>
    <div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" id="termsModal">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header align-items-center flex-nowrap py-3 pe-4 pe-lg-5">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                        <h2 class="fw-bold title--color m-0">Terms & Conditions</h2>
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <p>
                        Villa Group Resorts is making this vacation package offer available to you. Besides meeting the
                        qualifications and attending the Vacation Ownership presentation, there are no other
                        obligations. Come enjoy
                        your stay with us and learn about the benefits of Vacation Ownership!
                    </p>
                    <p>
                        Primary Travelers must meet all of the Resort's Qualifications below.
                    </p>
                    <p>
                        Resort Fees (if applicable) are collected during your verification call. This fee is a
                        reservation
                        processing fee.
                    </p>
                    <h2><b>Credit Card</b></h2>
                    <ul>
                        <li>The qualifying single person, OR one of the spouses in a legally married couple must bring
                            with them and
                            present to the hostess during check in for the presentation:</li>
                        <li>At least one Major Credit Card in the name of the qualified person(s). We DO NOT accept
                            Debit Cards,
                            Check Cards or Business Account Cards. We do not accept credit cards in the name of a
                            non-qualifying
                            person or in the name of a corporation.</li>
                        <li>WE ONLY ACCEPT VISA, MASTERCARD, DISCOVER or AMERICAN EXPRESS that are NOT associated with
                            your bank
                            accounts.</li>
                        <li>Expired credit cards DO NOT QUALIFY.</li>
                        <li>If these or any other qualifications are not met, your promotional reservation may be
                            cancelled without
                            notice.</li>
                    </ul>
                    <h2><b>Presentation</b></h2>
                    <ul>
                        <li>Attendance to a 90 minute vacation ownership presentation at The Villa Group Resort the day
                            after
                            arrival to Mexico is required.</li>
                        <li>You may not attend another vacation ownership presentation during your visit to the
                            destination.</li>
                        <li>You are not eligible if you have previously toured any of The Villa Group resorts within 6
                            months of
                            your arrival, or if you have already used a marketing promotion with Villa Group Resorts
                            twice.</li>
                    </ul>
                    <h2><b>Age Qualification</b></h2>
                    <h5>Cabo & Vallarta</h5>
                    <ul>
                        <li>Married couples - at least one person must be between the ages of 30 and 70.</li>
                        <li>Legally single women - between 30 and 70 years of age.</li>
                        <li>Legally single men - between 30 and 70 years of age.</li>
                        <li>YOU MAY BE ASKED TO PROVIDE COPIES OF ID AS PROOF.</li>
                    </ul>
                    <h5>Cancun</h5>
                    <ul>
                        <li>Married couples - at least one person must be between the ages of 30 and 70.</li>
                        <li>Legally single women or men- between 30 to 70 years of age.</li>
                        <li>YOU MAY BE ASKED TO PROVIDE COPIES OF ID AS PROOF.</li>
                    </ul>
                    <h5>Loreto</h5>
                    <ul>
                        <li>
                            Legally Married couples must be between 30-69 years old for American couples, or 30-64 years
                            old for
                            Canadian couples (either spouse may meet the age requirement).
                        </li>
                        <li>Legally Single American women 30 to 69 years and legally single Canadian women 30 to 64
                            years.</li>
                        <li>Legally single men - between 30 and 70 years of age, citizens of United States or Canada.
                        </li>
                        <li>YOU MAY BE ASKED TO PROVIDE COPIES OF ID AS PROOF.</li>
                    </ul>
                    <h2><b>Income</b></h2>
                    <ul>
                        <li>
                            Minimum $50,000 Annual Income (singles must meet this qualification on their own, or
                            combined income for
                            legally married couples).
                        </li>
                        <li>Must be employed full time or fully retired - part time employment does not qualify.</li>
                        <li>Must not be a full time student.</li>
                    </ul>
                    <h2><b>Language</b></h2>
                    <p>Both qualified participants must be fluent in either English or Spanish.</p>
                    <h2><b>Identification</b></h2>
                    <ul>
                        <li>
                            The qualified person(s) must present a major credit card and government issued photo ID at
                            the tour
                            reception desk.
                        </li>
                        <li>
                            Married couples may be asked to show proof of such (Government issued picture ID with
                            matching addresses,
                            and/or marriage certificate. Both spouses must bring ID.
                        </li>
                    </ul>
                    <h2><b>Marital Status</b></h2>
                    <ul>
                        <li>Offer is valid for legally married couples and legally single women or men.</li>
                        <li>All guests must attend the presentation, including all children in the reservation.</li>
                    </ul>
                    <h2><b>Countries</b></h2>
                    <p>
                        Offer is valid only for residents of the USA or Canada who meet all other qualifications. Valid
                        USA or
                        Canadian government issued ID must be presented by all guests. Non-citizens must have been
                        residents for a
                        minimum of 5 years and present USA or Canadian government issued ID.
                    </p>

                    <h2><b>Group Travel</b></h2>
                    <ul>
                        <li>
                            Maximum of 8 people per tour between adults and children. Specifically in a ratio of 4
                            adults and 4
                            children/teenagers.
                        </li>
                        <li>
                            Only one package purchase per friends or family is allowed for this or any similar
                            promotion. If friends
                            and/or family staying at the same resort during the same or similar dates occupies two
                            rooms, a higher
                            rate
                            must be paid for the 2nd room. Omission of information and/or misleading of pertinent
                            information
                            regarding
                            group travel may be subject to your reservation being cancelled without notice.
                        </li>
                    </ul>
                    <h2><b>Additional Terms</b></h2>
                    <ul>
                        <li>
                            Relatives of timeshare industry employees and timeshare industry related individuals,
                            Universal Vacation
                            Club
                            members, property owners in Los Cabos, Puerto Vallarta, Loreto or Cancun do not qualify for
                            this
                            promotion.
                        </li>
                        <li>
                            Package Purchasers not meeting the above qualifications, not completing the 90 minute tour,
                            or giving
                            purposeful misleading information, and/or omitting relevant information regarding package
                            qualifications,
                            will
                            negate this promotion and be subject to your reservation being cancelled without notice.
                        </li>
                        <li>
                            To receive this significantly discounted package you are obligated to participate in the 90
                            minute
                            informative
                            tour and presentation at the resort sponsoring your vacation, on the morning after your
                            arrival to Mexico.
                            You
                            are required to keep this day free of other obligations.
                        </li>
                        <li>
                            <b>
                                A major credit card must be presented at the tour check in to qualify for this promotion
                                and must be
                                shown by
                                the customer who qualifies for this promotional package. (Please note that company
                                credit cards,
                                debit/check
                                cards do not qualify.)
                            </b>
                        </li>
                        <li>
                            Villa Group Resorts reserves the right to cancel and/or change your reservation location to
                            an equal or
                            better
                            resort as circumstances so require it.
                        </li>
                        <li>
                            VILLA GROUP RESORTS RESERVES THE RIGHT TO ALTER ITS TERMS AND CONDITIONS AND TERMS OF
                            PROMOTIONS AS DEEMED
                            NECESSARY BY OPERATIONAL PROCEDURES AND/OR AS OTHERWISE MANDATED BY STATE OR FEDERAL LAWS.
                            THIS OFFER
                            SUPERSEDES ALL OTHER REPRESENTATIONS MADE IN REGARD TO THIS OFFER, VERBAL OR WRITTEN AND VGR
                            ASSUMES NO
                            RESPONSIBILITY FOR ANY VERBAL OR WRITTEN REPRESENTATIONS MADE IN CONJUNCTION WITH THIS OFFER
                            OTHER THAN
                            THOSE
                            SPECIFICALLY STATED HEREIN.
                        </li>
                    </ul>
                    <p>
                        <b>
                            IMPORTANT: FAILURE TO MEET ALL TERMS AND CONDITIONS MAY RESULT IN YOUR RESERVATION BEING
                            CANCELLED AT ANY
                            TIME
                            UP TO AND INCLUDING YOUR DATE OF ARRIVAL.
                        </b>
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-next" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        showModal() {
            let bannerModal = document.getElementById('termsModal');
            //eslint-disable-next-line
            const bannerModalInstance = new bootstrap.Modal(bannerModal);
            bannerModalInstance.show();
        }
    },
    mounted() {
        // this.showModal();
    }
}
</script>

<style scoped>
.title--color {
    color: #0C9491;
}
</style>