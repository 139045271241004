<template>
    <div class="row pe-3 ps-3 pt-3 text-center verify-email" v-show="tab == 1">
        <p class="title">
            Please verify your email
        </p>
        <p>
            You're almost there! We sent an email to {{ form.email }}.
        </p>
        <p>
            Just click on the link in that email to get full acces to our VIP Promotions. If you don´t see it, you may
            need to check your spam folder.
        </p>
        <p class="mb-1">
            Still can't find the email?
        </p>
        <button type="button" class="btn btn-blue-dark p-3" @click="tab = 0">
            RESEND EMAIL
        </button>
        <p class="pt-3">
            Need Help? Contact a Deal Expert
        </p>
        <h5>
            <span class="material-icons-outlined pe-3"> call </span><b>{{ websiteStore.getPhone }}</b>
        </h5>
    </div>
    <div class="row p-2" v-show="tab == 0">
        <form class="request-form" @submit.prevent="submitForm" novalidate>
            <p>
                Register to have VIP access
            </p>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="First Name" v-model="form.firstName" required />
                <div class="invalid-feedback">
                    Please type your first name
                </div>
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Last Name" v-model="form.lastName" required />
                <div class="invalid-feedback">
                    Please type your last name
                </div>
            </div>
            <div class="mb-3">
                <input type="email" class="form-control" placeholder="Email" v-model="form.email" required />
                <div class="invalid-feedback">
                    Please type your email
                </div>
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Phone Number" minlength="10" maxlength="10"
                    v-model="form.phone" required />
                <div class="invalid-feedback">
                    Please type your phone
                </div>
            </div>
            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Time to be reached" v-model="form.timeToReach"
                    required />
                <div class="invalid-feedback">
                    Please type the best time to reach
                </div>
            </div>
            <button type="submit" class="btn btn-blue-dark p-3 w-100" data-action='submit'>
                Submit
            </button>
        </form>
        <p class="text-center pt-3">
            <span class="log-in-lbl" @click="displayLoginForm"><u>Already registered?</u></span>
        </p>
        <form v-if="logInForm == true" @submit.prevent="logIn">
            <div class="input-group">
                <input type="email" class="form-control" placeholder="Enter your email" aria-label="Email"
                    aria-describedby="button-addon2" required v-model="omniSearch">
                <button class="btn btn-blue-dark" type="submit">Access</button>
            </div>
        </form>
    </div>
</template>

<script>
import { Swal2 } from '@/config/swal';
import axios from 'axios';
import { registerLead } from '@/api/eplat';
import { useWebsiteStore } from '@/store/website';

export default {
    setup() {
        const websiteStore = useWebsiteStore();

        return { websiteStore };
    },
    data() {
        return {
            form: {
                webhookID: "b3cd0544-63e8-2872-2d4d-20ac86736f7f",
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                timeToReach: "",
                tags: "#vip",
                leadTypeID: 104,
                leadSourceChannelID: this.websiteStore.getChannel,
                leadSourceID: this.websiteStore.getSource,
                campaignName: this.websiteStore.getCampaign
            },
            omniSearch: "",
            logInForm: false,
            tab: 0,
            myModal: "",

        }
    },
    methods: {
        hideModal() {
            let modal = document.querySelector('#vipModal');
            //eslint-disable-next-line
            const modalInstance = bootstrap.Modal.getInstance(modal);
            modalInstance.hide();
        },
        displayLoginForm() {
            this.logInForm = true
        },
        async logIn() {
            this.loadingMessage();
            let self = this
            await axios.get("/api/VipLead/Search?omniSearch=" + this.omniSearch)
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length > 0) {
                        this.websiteStore.setLeadID(data[0].leadID);
                        /* console.log("Es VIP");*/
                        self.redirectToVip();

                    } else {
                        self.errorMessageUser();
                        /* console.log("NO es");*/
                        /*console.log(response.data.length)*/
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });

            Swal2.close();
        },
        async submitForm({ target }) {

            if (target.checkValidity()) {
                this.loadingMessage();
                await registerLead(this.form)
                    .then(() => {
                        this.tab = 1
                        this.formEvent()
                    })
                    .catch((error) => {
                        console.log(error);
                        this.errorMessage()
                        this.tab = 0
                    }).finally(() => {
                        //Perform action in always
                    });

                Swal2.close();
            } else {
                target.classList.add('was-validated');
            }
        },
        formEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': 'form_success', 'formname': 'vip' });
        },
        errorMessage() {
            return Swal2.fire({
                icon: "warning",
                title: "Oops",
                text: "An unexpected error ocurred, please try again.",
                iconColor: "#ffd700",
            });
        },
        errorMessageUser() {
            this.logInForm = false;
            return Swal2.fire({
                icon: "warning",
                title: "Oops",
                text: "Seems like you are not registered yet.",
                iconColor: "#ffd700",
            });
        },
        loadingMessage() {
            this.loading = Swal2.mixin({
                customClass: {
                    loader: 'spinner',
                }
            })
            return this.loading.fire({
                text: "Submitting",
                iconColor: "#0c9491",
                width: 160,
                timerProgressBar: true,
                // timer: 3000,
                didOpen: () => {
                    this.loading.showLoading();
                },
            });
        },
        alertMessage() {
            this.tab = 3
            return Swal2.fire({
                icon: "success",
                title: "Thanks, all ready set.",
                text: "An agent will be in touch with you soon.",
                color: "#0C9491",
                iconColor: "#0C9491",
            });
        },
        redirectToVip() {
            //this.myModal = new Modal(document.getElementById('vipModal'));
            //this.myModal.dispose();
            this.omniSearch = ""
            Swal2.close()

            this.hideModal();

            this.$router.push({ name: 'vip-es' })
        }
    }
}
</script>

<style scoped>
.banner-redeem {
    background-image: url("@/assets/images/layout/forms-banners/redeem-banner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.verify-email {
    font: normal normal normal 12px/18px Poppins;
}

.form-control {
    border: 1px solid #0c9491;
}

::placeholder {
    font-size: 14px;
    padding-left: 0px;
}

textarea {
    resize: none;
}

p.title {
    font-size: 16px;
    font: normal normal bold 16px/24px Poppins;
    text-align: center;
}

.sub-request {
    background: transparent linear-gradient(90deg, #0c9491 0%, #3a2050 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    font: normal normal 12px/12px Arial;
}

.sub-request:hover {
    background: transparent linear-gradient(90deg, #3a2050 0%, #0c9491 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

h5 {
    color: #d50758;
}

@media (max-width: 991px) {
    .request-form {
        padding: 30px;
    }
}

.log-in-lbl {
    color: #d50758;
    cursor: pointer;
}
</style>