<template>
  <div>
    <div class="card h-100">
      <div class="position-relative h-auto overflow-hidden">
        <img :src="pckg.image.url" class="card-img" alt="..." />

        <div class="card-img-overlay d-flex flex-column p-2" :data-package-status="pckg.status"
          :data-package-vip="isVip && pckg.isVip" @click="toPackage(pckg)">
          <div>
            <div class="float-start">
              <span class="material-icons-outlined align-middle pe-2"> place </span>
              {{ destinationName }}
            </div>
            <div class="float-end vip-tag p-2 text-center" v-if="isVip">
              <span><b>$100 <sup class="currency-lbl-vip-card">USD</sup></b></span> <br>
              <span>Discount</span>
            </div>
          </div>
          <div class="mt-auto">
            <div>
              <span class="retail-price">${{ pckg.retailPriceUSD }} <sup>USD</sup> </span>
              <span class="price">${{ pckg.priceUSD }} <sup class="currency-lbl">USD</sup></span>
            </div>
            <span class="per">
              <div class="float-start">PER COUPLE</div>
              <div class="float-end">
                <span class="material-icons-outlined"> star_border </span>
                <span class="material-icons-outlined"> star_border </span>
                <span class="material-icons-outlined"> star_border </span>
                <span class="material-icons-outlined"> star_border </span>
                <span class="material-icons-outlined"> star_border </span>
              </div>
            </span>
          </div>
        </div>

      </div>
      <div class="card-body d-flex flex-column">
        <h5 class="fs-3 fw-bold pb-2 text-blue">{{ pckg.name }}</h5>
        <p class="fs-6 pb-2">
          {{ pckg.nights + 1 }} Days & {{ pckg.nights }} nights for
          {{ pckg.adults }} adults.*
          <br><span class="children-lbl">*Ask about including children.</span>
        </p>
        <router-link
          :to="{ name: 'Package', params: { destinationSlug: destinationSlug, packageSlug: pckg.slug, vip: isVip } }"
          custom v-slot="{ navigate }">
          <button :style="getBtnStyle" :class="{'campaign-bg btn p-3 mt-auto': websiteStore.isCampaign,'btn btn-blue-dark p-3 mt-auto': websiteStore.isCampaign==false}" :disabled="pckg.status == 'sold out'" @click="navigate">EXPLORE
            THIS OFFER</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useWebsiteStore } from '@/store/website';
export default {
  props: ["package", "destinationName", "destinationSlug", "isVip"],
  setup() {
    const websiteStore = useWebsiteStore();
    return {
      websiteStore
    }
  },
  data() {
    return {
      pckg: this.package,
      packgDestination: this.package.destination,
      isCampaign:'true',
      btnCampaignStyle: ''
    };
  },
  computed:{
    getBtnStyle() {
      this.setBtnStyle()
      return this.btnCampaignStyle
    }
  },
  methods: {   
    toPackage(pkg) {
      if (pkg.status != 'sold out') {
        this.$router.push({ name: 'Package', params: { destinationSlug: this.destinationSlug, packageSlug: pkg.slug, vip: this.isVip && pkg.isVip } })
      }
    },
    setBtnStyle() {
      if(this.websiteStore.isCampaign) {
        this.btnCampaignStyle=this.websiteStore.campaign.btnColors
      }
    }
  },
  mounted() {
    // console.log(this.pckg.destinationSlug)
  }
};
</script>

<style scoped>
:not([data-package-status="sold out"]).card-img-overlay {
  cursor: pointer;
}

.card-img-overlay:not([data-package-status=""])[data-package-status]::after {
  z-index: 3;
  position: absolute;
  text-align: center;
  padding: 0.5rem 0;
  transform: rotateZ(45deg);
  font-weight: 600;
  box-shadow: 1px 1px 5px 3px #0000005d;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-shadow: 4px 4px 4px #0000005d;
}

.card-img-overlay:not([data-package-status=""]):not([data-package-vip="true"])[data-package-status]::after {
  content: attr(data-package-status);
  width: 55%;
  right: -15%;
  top: 10%;
}

.card-img-overlay[data-package-status="sold out"]::after {
  background: #c4c4c4;
  color: white;
}

.card-img-overlay[data-package-status="new"]::after {
  background: #D50758;
  color: white;
}

.card-img-overlay {
  color: #ffff;
  box-shadow: 0px 2px 81px 22px rgba(0, 0, 0, 0.7) inset;
  -webkit-box-shadow: 0px 2px 81px 22px rgba(0, 0, 0, 0.7) inset;
  -moz-box-shadow: 0px 2px 81px 22px rgba(0, 0, 0, 0.7) inset;
}

.place {
  font-size: 14px;
}

.retail-price {
  display: block;
  text-align: left;
  text-decoration: line-through;
  font: normal normal normal 12px/24px Poppins;
}

.price {
  text-align: left;
  font: normal normal bold 24px/24px Poppins;
}

.per {
  text-align: left;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
}

.card-body {
  background-color: #fff;
}

.span {
  display: block;
}

.vip-tag {
  background-color: #D50758;
  right: 0px;
  box-shadow: 1px 1px 5px 3px #00000021;
  top: 50%;
  transform: translateY(-80%);
  position: absolute;
}

.currency-lbl {
  font-size: 14px !important;
}

.currency-lbl-vip-card {
  font-size: 10px !important;
}

.children-lbl {
  font-size: 13px;
}

.campaign-bg{
background-color: red;
}

.btn-campaign {
  background: rgb(220,40,52);
  background: linear-gradient(270deg, rgba(220,40,52,1) 0%, rgba(2,60,97,1) 26%, rgba(35,57,90,1) 67%, rgba(220,40,52,1) 100%);
  color: white;
  border: 1px solid rgba(243,219,122,1)
}

.btn.btn-campaign{
  background-position: center;
  transition: 0.25s background-position, box-shadow ease;
}

.btn.btn-campaign:hover,
 .btn.btn-campaign:focus
{
  background: rgb(2,60,97);
  background: linear-gradient(270deg, rgba(2,60,97,1) 0%, rgba(220,40,52,1) 30%, rgba(220,40,52,1) 63%, rgba(35,57,90,1) 100%);
  border: 1px solid rgb(236,138,29);
  transition: 0.25s background ease;
}

.btn.btn-campaign:focus{
      box-shadow: 3px 3px 3px #0c94925d;
  }
</style>