<template>

    <TheNavigation />
    <slot />
    <TheBanner />
    <TheWheel />
    <TheLottery />
    <TheFooter />
</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue';
import TheFooter from '@/components/TheFooter.vue'
import TheBanner from '@/components/TheBanner.vue'
import TheWheel from '@/components/TheWheel.vue'
import TheLottery from '@/components/TheLottery.vue'
import "@/assets/site.css";

export default {
  components: {
    TheNavigation,
        TheFooter,
        TheBanner,
        TheWheel,
        TheLottery
        },
    }

</script>

<style>

</style>