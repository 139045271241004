import axios from 'axios';

const weatherApi = axios.create({
    baseURL: 'https://api.openweathermap.org/data/2.5'
});

const appId = '62d6e65fb65cf3e61c1de1bab390ad35';

//Obtiene la informacion del clima de la coordenadas enviadas
export const getWeather = async (lat = 0, lon = 0, units = 'imperial') => {
    const response = await weatherApi.get(`/weather?lat=${lat}&lon=${lon}&units=${units}&appid=${appId}`)
        .then(({ data }) => data)
        .catch(x => x);

    return response;
}


//Obtiene la informacion de forecast de las coordenadas enviadas
export const getForecast = async (lat = 0, lon = 0, units = 'imperial') => {
    const response = await weatherApi.get(`/forecast?lat=${lat}&lon=${lon}&units=${units}&appid=${appId}`)
        //Retorna mas cosas sin embargo parece mas informacion descriptiva de lo que regresa, en cuestiones tecnicas
        //Considero ciudad y la lista del clima como lo importante y que desde mi perspectiva no lo deben de saber los demas modulos
        .then(({ data }) => ({ list: data.list, city: data.city }))
        .catch(x => x);

    return response;
}