<template>
    <slot />
</template>

<script>


export default {

}
</script>

<style></style>