<template>
    <TheNavigation />
    <slot />
    <TheBanner />
    <TheFooter />
</template>

<script>
import TheNavigation from '@/components/es/TheNavigation.vue';
import TheFooter from '@/components/es/TheFooter.vue'
import TheBanner from '@/components/es/TheBanner.vue'
import "@/assets/site.css";


export default {
  components: {
    TheNavigation,
        TheFooter,
        TheBanner
  }
}
</script>

<style>
</style>