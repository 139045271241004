<template>
    <div class="mt-5 pt-3 text-center weather-card px-3 pb-3 shadow" :class="bgClass" v-if="!loading">
        <div>
            <img :src="weather.iconUrl" alt="" width="100" class="weather-icon shadow rounded-circle">
            <p class="description">{{ weather.description }}</p>
            <p class="destination">{{ weather.name }}</p>
            <p class="degrees">{{ weather.temp }} ºF</p>
            <p class="date">Today, {{ currentDate }}</p>
        </div>
        <div class="row ps-2 pe-2">
            <div class="col-6 details-box p-2 d-block">
                <div class="text-end">
                    <span class="lbl">Wind: </span>
                    <span class="description">{{ weather.windSpeed }}</span> <br>
                    <span class="lbl"> Humidity: </span>
                    <span class="description">{{ weather.humidity }}%</span>
                </div>
            </div>
            <div class="col-6  details-box p-2">
                <div class="text-start ">
                    <span class="lbl"> Sunrise: </span>
                    <span class="description">{{ weather.sunrise }}</span><br>
                    <span class="lbl"> Sunset: </span>
                    <span class="description">{{ weather.sunset }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getWeather } from '@/api/weather';
import { getWeatherImage } from '@/helpers/weather';

export default {
    props: {
        coordenates: {
            type: Object,
        },
        units: {
            type: String,
            default: 'imperial'
        },
        bgClass: {
            type: String,
            default: 'bg-white'
        }
    },
    watch: {
        coordenates: {
            deep: true,
            handler() {
                this.getWeather();
            }
        }
    },
    data() {
        return {
            weather: null,
            loading: true,
            currentDate: new Date().getDate() + " " + new Date().toLocaleString('default', { month: 'short' }),
        }
    },
    methods: {
        async getWeather() {
            this.loading = true;
            this.weather = await getWeather(this.coordenates.lat, this.coordenates.lon, this.units).then(x => {
                const weather = {};

                const weatherTime = x.weather[0];

                weather.iconUrl = getWeatherImage(weatherTime.description);
                weather.description = weatherTime.description;
                weather.name = x.name;
                weather.temp = x.main.temp;
                weather.windSpeed = x.wind.speed;
                weather.humidity = x.main.humidity;
                weather.sunrise = this.getTime(x.sys.sunrise);
                weather.sunset = this.getTime(x.sys.sunset);

                return weather;
            });
            this.loading = false;
        },
        getTime(date) {
            const newDate = new Date(date * 1000);

            let hours = newDate.getHours();
            let minutes = newDate.getMinutes();
            let meridian = 'AM';

            if (hours > 11 && hours < 23) {
                meridian = 'PM';

                if (hours > 12) hours = hours - 12;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`
            }
            return `${hours}:${minutes} ${meridian}`;
        }
    },
    mounted() {
        this.getWeather();
    }
}
</script>