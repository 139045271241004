<template>
    <div class="fixed-top" style="background-color: white">
        <div class="contact-bar d-none d-lg-block">
            <div class="row">
                <div class="col-7 d-flex follow">
                    <span class="">
                        S&itilde;guenos
                        <a class="navbar-brand mx-3" href="https://www.instagram.com/vacationdealsallinclusive/" target="_blank"
                           rel="noopener noreferrer">
                            <svg id="instagram" class="icon" xmlns="http://www.w3.org/2000/svg" width="19.884" height="19.879"
                                 viewBox="0 0 19.884 19.879">
                                <path class="icon-color" id="instagram-2" data-name="instagram"
                                      d="M9.944,4.843a5.1,5.1,0,1,0,5.1,5.1A5.089,5.089,0,0,0,9.944,4.843Zm0,8.411A3.314,3.314,0,1,1,13.258,9.94a3.32,3.32,0,0,1-3.314,3.314Zm6.494-8.619A1.189,1.189,0,1,1,15.25,3.446,1.186,1.186,0,0,1,16.438,4.634Zm3.376,1.207a5.883,5.883,0,0,0-1.606-4.165A5.922,5.922,0,0,0,14.043.07c-1.641-.093-6.561-.093-8.2,0a5.913,5.913,0,0,0-4.165,1.6A5.9,5.9,0,0,0,.07,5.837c-.093,1.641-.093,6.561,0,8.2A5.883,5.883,0,0,0,1.676,18.2,5.929,5.929,0,0,0,5.841,19.81c1.641.093,6.561.093,8.2,0A5.883,5.883,0,0,0,18.208,18.2a5.922,5.922,0,0,0,1.606-4.165c.093-1.641.093-6.556,0-8.2ZM17.694,15.8a3.355,3.355,0,0,1-1.89,1.89c-1.309.519-4.414.4-5.86.4s-4.556.115-5.86-.4a3.355,3.355,0,0,1-1.89-1.89c-.519-1.309-.4-4.414-.4-5.86s-.115-4.556.4-5.86a3.355,3.355,0,0,1,1.89-1.89c1.309-.519,4.414-.4,5.86-.4s4.556-.115,5.86.4a3.355,3.355,0,0,1,1.89,1.89c.519,1.309.4,4.414.4,5.86S18.213,14.5,17.694,15.8Z"
                                      transform="translate(0 0)" fill="#fff" />
                            </svg>
                        </a>
                        <a class="navbar-brand" href="https://www.facebook.com/VacationDealsAllInclusive/" target="_blank"
                           rel="noopener noreferrer">
                            <svg id="facebook-f" class="icon-f me-3" xmlns="http://www.w3.org/2000/svg" width="12.164" height="22.712"
                                 viewBox="0 0 12.164 22.712">
                                <path id="facebook-f-2" class="icon-color" data-name="facebook-f"
                                      d="M11.367,12.775,12,8.665H8.054V6a2.055,2.055,0,0,1,2.317-2.221h1.793V.278A21.865,21.865,0,0,0,8.981,0C5.733,0,3.61,1.969,3.61,5.532V8.665H0v4.11H3.61v9.936H8.054V12.775Z"
                                      transform="translate(0)" fill="#fff" />
                            </svg>
                        </a>
                        <!-- <a class="navbar-brand me-3" href="https://www.tiktok.com/@vacationdealsvdai" target="_blank"
      rel="noopener noreferrer">
      <img src="../assets/icons/Tik_tok.svg" alt="" width="13">
    </a> -->
                    </span>
                </div>
                <div class="col-5 d-flex r-q align-items-center">
                    <router-link class="align-middle" :to="{
            name: 'personalizar',
          }">                                                               
                        <span class="align-middle pe-3">Solicitar Cotizaci&oacute;n</span>
                    </router-link>
                    <router-link class="align-middle" :to="{
            name: 'solicitar-paquete',
          }">
                        <span class="align-middle redeem-label ps-3">Canjear Mi Paquete</span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <router-link class="navbar-brand p-0 py-lg-2" :to="{ name: 'loreto' }">
                    <img src="~@/assets/images/layout/logoVDAI.svg" width="150" />
                </router-link>
                <div id="hamburger-1" class="hamburger toggler border-0 d-lg-none" type="button" data-bs-toggle="collapse"
                     data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                     aria-label="Toggle navigation">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </div>
                <div class="navbar-collapse collapse flex-column justify-content-between flex-lg-row justify-content-lg-end mt-2 mt-lg-0"
                     id="navbarNavDropdown">
                    <ul class="col-lg-auto navbar-nav pe-lg-3 px-xl-5 ms-auto flex-nowrap container">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'loreto' }">Inicio</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-animation="0"
                               data-bs-target="#navTopDestinations"
                               :class="$route.name == 'destination' ? 'router-link-exact-active' : ''" aria-expanded="false">
                                Paquetes en Loreto
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown" id="navTopDestinations">
                                <router-link v-for="pkg in bestDeals" custom v-slot="{ navigate }"
                                             :to="{ name: 'Package-es', params: { packageSlug: pkg.slug, destinationSlug: 'loreto-es' } }" :key="pkg.id">
                                    <li class="dropdown-item py-2 pe-pointer" @click="navigate">{{ pkg.name }}</li>
                                </router-link>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{
                name: 'resenias',
              }">
                                Rese&ntilde;as
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                          <a class="nav-link" aria-current="page" href="#">Blog</a>
                        </li> -->
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'faqs-es' }">
                                FAQs
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link pe-pointer" :to="{ name: 'vip-es' }" v-if="websiteStore.isVip">
                                Promociones VIP
                            </router-link>
                            <span id="modalb" class="nav-link pe-pointer" aria-current="page" data-bs-toggle="modal"
                                  data-bs-target="#vipModal" v-else>Promociones VIP</span>
                        </li>
                    </ul>
                    <div class="d-lg-none w-75 mx-auto" style="padding-bottom: 7rem;">
                        <router-link class="btn btn-request-p w-100" :to="{ name: 'request' }">
                            Solicitar Cotizaci�n
                        </router-link>
                        <router-link class="btn btn-request-p mt-3 w-100" :to="{ name: 'redeem' }">
                            Canjear Mi Paquete
                        </router-link>
                    </div>

                    <div class="col-lg-auto h-75 ps-lg-3 ps-xl-5 collapse-footer d-none d-lg-block">
                        <a class="nav-link" :href="'tel:' + websiteStore.getPhone">
                            <b>Llama gratis</b><br />
                            {{ websiteStore.getPhone }}
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="vipModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body">
                    <VipForm />
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">hewy</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

    // import { Collapse } from "bootstrap";
    import VipForm from "../es/VipForm.vue";
    import { useWebsiteStore } from "@/store/website";
    // import jivo_api from "jivo_api"

    export default {
        props: {
            teamPhone: String
        },
        setup() {
            const websiteStore = useWebsiteStore();

            return { websiteStore };
        },
        data() {
            return {
                collapse: {},
                // toggler: false,
                destinations: this.websiteStore.destinations.map(({ slug, name, id }) => ({ slug, name, id }))
                // teamPhone: '877 692 3883',
                // team:'',
                // localStore: localStorage.getItem('Team'),
                // params: new URLSearchParams(window.location.search)
            };
        },
        components: {
            VipForm
        },
        methods: {
            initNavBar() {
                const navBarCollapse = document.querySelector("#navbarNavDropdown");
                /*eslint-disable-next-line */
                const collapse = new bootstrap.Collapse(navBarCollapse, {
                    toggle: false,
                });


                navBarCollapse.addEventListener('hide.bs.collapse', this.onHideNavBar)
                navBarCollapse.addEventListener('show.bs.collapse', this.onShowNavBar)

                return collapse;
            },
            isDropdownList(currentOption) {
                if (currentOption.length > 0) {
                    return true;
                }
            },
            onHideNavBar({ target }) {
                //Se revisa que sea el id, ya que se escucha el evento del collapse interno del menu de navegacion
                if (target.id == "navbarNavDropdown") document.body.setAttribute('show-collapse', false);
            },
            onShowNavBar({ target }) {
                if (target.id == "navbarNavDropdown") document.body.setAttribute('show-collapse', true);
            }
            // selectedToggler() {
            //   this.toggler = !this.toggler;
            // },
            // selectedOption() {
            //   let menu = document.getElementsByClassName("navbar-toggler")[0];
            //   menu.click();
            //   // this.collapse.hide();
            //   // this.selectedToggler();
            // },
            // createLocal(){
            //   if (localStorage.getItem('Team') != null || localStorage.getItem('Team') != undefined) {
            //     this.teamPhone = JSON.parse(localStorage.getItem('Team'))
            //   } else {
            //     if (this.$route.query.Team != null) {
            //       this.getTeam(this.$route.query.Team)
            //       localStorage.Team = JSON.stringify(this.teamPhone)
            //     }
            //   }
            //   },
            //   getTeam(team){
            //     switch (team) {
            //       case 'VTA:VDAI-OB-MAI':
            //         this.teamPhone='888 669 9803'
            //         break;
            //         case 'VTA:VDAI-IB-MAI':
            //         this.teamPhone='888 273 1373'
            //         break;
            //         case 'GDL:VDAI-OB-MAI':
            //         this.teamPhone='855 704 7349'
            //         break;
            //         case 'GDL:VDAI-IB-MAI':
            //         this.teamPhone='866 4851 197'
            //         break;
            //     }
            //   }
        },
                computed: {
            bestDeals() {
                        return this.websiteStore.getPackages({ destinationSlug: 'loreto-es' });
                // let packages = [];
                // this.websiteStore.destinations.forEach((dest) => {
                //   dest.packages.forEach((pkg) => {
                //     if (pkg.includeInHome) {
                //       let _package = { ...pkg };
                //       // _package.slug = dest.slug + "/" + pkg.slug;
                //       _package.destination = dest.name;
                //       _package.destinationSlug = dest.slug
                //       packages.push(_package);
                //     }
                //   });
                // });
                // return packages;
            },
        },
        mounted() {
            this.collapse = this.initNavBar();

            this.$router.afterEach(() => {
                this.collapse.hide();
            });
        },
        unmounted() {
            this.collapse._element.removeEventListener('hide.bs.collapse', this.onHideNavBar)
            this.collapse._element.removeEventListener('show.bs.collapse', this.onShowNavBar)
        }
    };
</script>


<style scoped>
    .navbar-light .navbar-toggler {
        color: transparent;
        border-color: transparent;
        padding: 0px;
    }

    .r-q {
        background-color: #0c9491;
        color: #ffffff;
    }

    .contact-bar a {
        font-size: 12px !important;
    }

    .navbar {
        background-color: white;
    }

    .nav-link {
        color: #4e4e4f;
        font-size: 16px;
        padding: 0px;
        text-decoration: none;
    }

    .navbar-toggler:focus {
        outline: none;
        box-shadow: 0 0 0 0 rem;
    }

    .dropdown-item {
        color: #ffffff;
        background-color: #0c9491;
        padding: 15px;
        border-bottom: 1pt solid;
        border-color: #0a6b69;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        background-color: transparent;
        top: 51px;
        border: none;
    }

    .icon-color {
        fill: #4e4e4f;
    }

    .icon {
        width: 10.43px;
    }

    .icon-f {
        width: 6.38px;
    }

    .follow {
        padding-left: 17%;
        font-size: 12px;
        background-color: #EFEFEF;
    }

    .redeem-label {
        border-left: 1px solid #ffffff;
    }

    .align-middle {
        text-decoration: none;
        color: #ffffff;
    }

    .three {
        padding: 0px 0px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        color: #ecf0f1;
        text-align: center;
    }

    .hamburger .line {
        width: 45pt;
        height: 3pt;
        background-color: #00afac;
        display: block;
        margin: 15px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .hamburger:hover {
        cursor: pointer;
    }

    /* ONE */

    #hamburger-1[aria-expanded="true"] .line:nth-child(2) {
        opacity: 0;
    }

    #hamburger-1[aria-expanded="true"] .line:nth-child(1) {
        -webkit-transform: translateY(25px) rotate(45deg);
        -ms-transform: translateY(25px) rotate(45deg);
        -o-transform: translateY(25px) rotate(45deg);
        transform: translateY(25px) rotate(45deg);
    }

    #hamburger-1[aria-expanded="true"] .line:nth-child(3) {
        -webkit-transform: translateY(-13px) rotate(-45deg);
        -ms-transform: translateY(-13px) rotate(-45deg);
        -o-transform: translateY(-13px) rotate(-45deg);
        transform: translateY(-13px) rotate(-45deg);
    }

    .collapse-footer {
        padding-left: 1%;
        border-left: 3px solid #00000029;
        color: #3a2050;
    }

    .nav-link:hover,
    .router-link-exact-active {
        color: #0c9491;
    }

    .nav-link:focus {
        color: #0c9491;
    }

    .dropdown-item:hover {
        background-color: #167b79;
        color: #ffffff;
    }

    @media (max-width: 992px) {
        .dropdown-item:first-child {
            margin-top: -1rem;
        }

        .nav-link {
            color: white;
            padding: 1rem 1rem;
        }

        li a.nav-link,
        li span.nav-link {
            font-weight: 600;
        }

        .dropdown-menu,
        .dropdown-item {
            padding-left: 2rem;
        }

        .nav-link:focus {
            color: #ffffff;
        }

        .nav-link:hover {
            color: #ffffff;
        }

        .navbar-collapse {
            background-color: #0c9491;
            overflow: auto;
            min-height: calc(100vh - 8.9rem);
        }

        .navbar-light .navbar-nav .nav-link {
            color: rgba(255, 255, 255);
            font-size: 16pt;
        }

        .nav-item:not(:last-child) {
            border-bottom: 1pt solid;
            border-color: #0a6b69;
            color: white !important;
        }

        .btn-request-p {
            background-color: #0a6b69;
            width: 273pt;
            color: #ffffff;
            font-size: 16pt;
            padding: 1rem 0;
        }

        a.align-middle {
            padding: 10px;
        }

        .collapse-footer {
            background-color: #0a6b69;
            font-size: 16pt;
            color: #ffffff;
            padding-left: 100% !important;
        }

        .dropdown-menu {
            position: static;
            background-color: transparent;
            border: none;
        }

        .dropdown-item {
            color: #ffffff;
            padding: 0px;
            border-bottom: none;
        }

        .nav-link {
            color: #ffffff;
            font-family: "Poppins", sans-serif;
            text-decoration: none;
        }

        .navbar-toggler:focus {
            outline: none;
            box-shadow: 0 0 0 0 rem;
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: relative;
            background-color: transparent;
            top: 0px;
        }
    }

    #navbarNavDropdown {
        width: 100vw;
        margin: 0 -1.5rem;
    }

    .navbar-collapse {
        flex-basis: none;
    }

        .navbar-collapse.show {
            display: flex;
        }

    @media (min-width: 576px) {
        #navbarNavDropdown {
            margin: 0 calc((100vw - 100%) / -2);
        }

            #navbarNavDropdown > * {
                max-width: 540px;
            }
    }

    @media (min-width: 768px) {
        /* #navbarNavDropdown {
      margin: 0 calc((100vw - 680px) / -2);
    } */

        #navbarNavDropdown > * {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        #navbarNavDropdown {
            width: auto;
            margin: 0;
        }

            #navbarNavDropdown > * {
                max-width: auto;
                margin: 0;
                padding: 0;
            }
    }

    .button_c586 {
        border-radius: 65px !important;
        box-shadow: 0 19px 38px 0 rgba(34, 36, 43, .3);
        height: 65px;
        margin-bottom: 20px !important;
        position: relative;
        text-align: center;
        transform: scale(1);
        width: 65px;
        display: none !important;
    }
</style>