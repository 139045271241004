<template>
    <div class="row h-100 align-items-center ">
        <div class="col-1 d-none d-lg-block">
            <span class="material-symbols-outlined btn-arrow" @click="previousSlide()">
                &lt;
            </span>
        </div>
        <div class="col-12 col-lg-10 text-start">
            <h5 class=" d-none d-lg-block text-start">Customer Stories</h5>
            <p class="d-none d-lg-block">
                Delve into the stories of our delighted travelers in our testimonial videos, exclusively
                showcasing our all-inclusive beach getaway packages in Mexico. From serene coastal retreats
                to thrilling seaside adventures, our vacation deals have left indelible memories. Let their
                reviews ignite your wanderlust for the perfect beach holiday.
            </p>
            <p class="d-none d-lg-block">
                Watch now and immerse yourself in the allure of our all-inclusive Mexico beach escapes
                through the eyes of our satisfied adventurers.
            </p>
            <swiper :slidesPerView="1" :spaceBetween="-70" :loop="true" :pagination="{
                clickable: true,
            }" :breakpoints="{
                '640': {
                    slidesPerView: 3,
                    spaceBetween: 1,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: -60,
                },
                '1024': {
                    slidesPerView: 3,
                    spaceBetween: -50,
                },
                '1400': {
                    slidesPerView: 3,
                    spaceBetween: -70,
                },

            }" :modules="modules" class="mySwiper py-2 ps-0" id="swiperVideoGallery">
                <swiper-slide v-for="video in websiteStore.reviewsVideoGallery" :key="video.posterUrl"
                    @click="selectVideo(video.videoUrl)">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 text-center">
                                <div><img class="rounded-3 w-75 non-selected"
                                        :class="{ 'selected': video.videoUrl === websiteStore.selectedVideo }"
                                        :src="video.posterUrl" /></div>
                            </div>
                            <div class="col text-white position-absolute top-50 play_circle z-3 d-none d-lg-grid">
                                <img src="../assets/icons/Play.svg" alt="" class="w-25"> Watch Video
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="col-1">
            <span class="material-symbols-outlined btn-arrow d-none d-lg-block" @click="nextSlide()">
                &gt;
            </span>
        </div>
    </div>
</template>

<script>
import { useWebsiteStore } from '@/store/website';
// import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Fancybox } from '@fancyapps/ui';
import "@fancyapps/ui/dist/fancybox.css";

export default {
    data() {
        return {
            selectedVideo: '',
            imgStyle: {
                filter: 'brightness(0.8)'
            }
        }
    },
    setup() {
        const websiteStore = useWebsiteStore();
        const getImageUrl = (name) => {
            return new URL(`../assets/${name}`, import.meta.url).href
        }
        return {
            getImageUrl,
            modules: [Navigation, Pagination],
            websiteStore,
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        nextSlide() {
            const swiper = document.querySelector('#swiperVideoGallery').swiper;
            // Now you can use all slider methods like
            swiper.slideNext();
        },
        previousSlide() {
            const swiper = document.querySelector('#swiperVideoGallery').swiper;
            // Now you can use all slider methods like
            swiper.slidePrev();
        },
        selectVideo(videoId) {
            this.playVideo()
            this.websiteStore.selectedVideo = videoId
            this.setImgStyle(videoId)
        },
        setImgStyle(url) {
            if (url === this.websiteStore.selectedVideo) {
                this.imgStyle = {
                    filter: 'brightness(0.8)'
                }
            }
        },
        playVideo() {
            let myVideo = document.getElementById("videoReview");

            var playPromise = myVideo.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    // We can now safely pause video...
                    console.log(_)
                    myVideo.play();
                })
                    .catch(error => {
                        console.log(error)
                        // Auto-play was prevented
                        // Show paused UI.
                    });
            }
        }
    },
    computed: {
        getImgStyle() {
            return this.imgStyle
        }
    },
    mounted() {
        Fancybox.bind('[data-fancybox="gallery"]', {
            //
        });
    }
}
</script>

<style scoped>
.swiper-pagination-bullet-active {
    background-color: #0c9491;
}

.swiper {
    /* width: 100%;
  height: 100%; */
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-btn {
    font-size: 18px;
}

h5 {
    color: #6a6a6b;
    font-weight: bold;
}

.non-selected {
    filter: brightness(1);
}

.selected {
    filter: brightness(0.8);
}

.material-symbols-outlined {
    font-size: 35px;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

.btn-arrow {
    position: relative;
    top: 100px;
    cursor: pointer;
}

.play_circle {
    opacity: 0%;
    left: -25px;
    top: 41% !important;
}

.swiper-slide:hover {
    cursor: pointer;

    .play_circle {
        opacity: 100%;
        filter: brightness(1);
    }

}

.play_circle {
    font-size: 12px;
}

.play_circle.d-lg-grid {
    justify-items: center;
    line-height: 2rem;
}

.non-selected:hover {
    filter: brightness(0.5);

}
</style>