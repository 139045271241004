<template>
  <div class="container-fluid footer-bg text-white p-4 px-5 text-center ">
    <div class="row d-flex justify-content-between">
      <div class="col-12 col-lg-6 text-lg-start">
        Vacation Deals All-Inclusive
      </div>
      <div class="col-12 col-lg-6 text-lg-end">
        Copyright © 2024 | All Righs Reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer-bg {
  background-color: #13647f;
  margin-bottom: 148px;
}



@media (min-width: 992px) { 

  .footer-bg {
    margin-bottom: 0px;
}
 
 }

</style>