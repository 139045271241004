<template>
    <swiper :slidesPerView="1" :spaceBetween="-50" :pagination="{
        clickable: true, 
    }" :loop="true" :breakpoints="{
        '640': {
            slidesPerView: 3,
            spaceBetween: 1,
        },
        '768': {
            slidesPerView: 4,
            spaceBetween: 1,
        },
        '1024': {
            slidesPerView: 7,
            spaceBetween: 1,
        },
    }" :modules="modules" class="mySwiper p-4 ps-lg-5 ps-0">
        <swiper-slide v-for="img in websiteStore.detailsGallery" :key="img.coverUrl">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <a data-fancybox="gallery" :href="img.imgUrl">
                            <img class="rounded-0" :src="img.coverUrl" />
                        </a>
                    </div>
                </div>
            </div>
        </swiper-slide>
    </swiper>
    <div class="container-fluid px-5 pb-5 d-none d-lg-block">
        <div class="row justify-content-between gallery-btn">
            <div class="col px-4 d-flex" @click="previousSlide()">
                <img src="../../assets/icons/arrow_back.svg" alt=""  class="pe-2"> Previous
            </div> 
            <div class="col text-end px-4" @click="nextSlide()">
                Next<img src="../../assets/icons/arrow_forward.svg" alt=""  class="ps-2">
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from '@/store/landing';
// import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';

export default {
    data() {
        return {
        }
    },
    setup() {
        const getImageUrl = (name) => {
            return new URL(`../../assets/${name}`, import.meta.url).href
        }
        return {
            getImageUrl,
            modules: [Navigation, Pagination],
            websiteStore: useStore()
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        nextSlide() {
            const swiper = document.querySelector('.swiper').swiper;
            // Now you can use all slider methods like
            swiper.slideNext();
        },
        previousSlide() {
            const swiper = document.querySelector('.swiper').swiper;
            // Now you can use all slider methods like
            swiper.slidePrev();
        }
    },
    mounted() {
        // const swiper = new Swiper('.swiper', {
        //     modules: [Navigation, Pagination],
        //     speed: 400,
        //     pagination: {
        //         el: '.swiper-pagination',
        //         type: 'bullets',
        //     },
        //     slidesPerView: 1,
        //     spaceBetween: 30,
        //     freeMode:"true",
        //     watchOverflow: 'true',
        //     // Responsive breakpoints
        //     breakpoints: {
        //         // when window width is >= 320px
        //         320: {
        //             slidesPerView: 1,
        //             spaceBetween: -80,
        //              pagination: 'true',
        //              pagination: {
        //         el: '.swiper-pagination',
        //         type: 'bullets',
        //     },
        //             // centeredSlides:"true"
        //         },
        //         //when window width is >= 480px
        //         576: {
        //             slidesPerView: 2,
        //             spaceBetween: -20,
        //               pagination: 'true',
        //               pagination: {
        //         el: '.swiper-pagination',
        //         type: 'bullets',
        //     },
        //         },
        //         768: {
        //             slidesPerView: 2,
        //             spaceBetween: -160,
        //               pagination: 'true',
        //               pagination: {
        //         el: '.swiper-pagination',
        //         type: 'bullets',
        //     },
        //         },
        //         // when window width is >= 640px
        //         992: {
        //             slidesPerView: 3,
        //             spaceBetween: -130,
        //             pagination: 'false'

        //         },
        //         1200: {
        //             slidesPerView: 4,
        //             spaceBetween: 0,
        //             pagination: 'false'

        //         },
        //         1400: {
        //             slidesPerView: 4,
        //             spaceBetween: -160,
        //             pagination: 'false'

        //         }
        //     }
        // });

        Fancybox.bind('[data-fancybox="gallery"]', {
            //
        });
    }
}
</script>

<style scoped>
    #app>*:nth-child(3) {
        padding-top: 0px!important;
    }
.arrow-down-icon {
    width: 35px;
    position: absolute;
    left: 50% !important;
    bottom: -2px;
    background-color: #ffff;
}

.swiper-pagination-bullet-active {
    background-color: #0c9491;
}

.swiper {
    /* width: 100%;
  height: 100%; */
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-btn {
    font-size: 18px;
}
</style>