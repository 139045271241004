<template>
    <div class="footer-form py-5">
        <div class="container text-center">
            <img src="~@/assets/icons/newsletter-form-icon.svg" alt="Girl in a jacket" width="50" />
            <form @submit.prevent="submitForm" class="row justify-content-center gy-4 gx-3 pt-3" novalidate>
                <div class="col-12 text-center fs-4">
                    Suscr&iacute;bete y recibe ofertas exclusivas
                </div>
                <div class="col-xl-10">
                    <div class="row gy-4 gx-3 justify-content-center">
                        <div class="col-md col-9">
                            <input type="text" class="form-control input" placeholder="Ingresa tu nombre"
                                   v-model.trim="form.firstName" required />
                            <div class="invalid-feedback">
                                Ingresa un nombre
                            </div>
                        </div>
                        <div class="col-md col-9">
                            <input type="text" class="form-control input" placeholder="Ingresa tu apellido "
                                   v-model.trim="form.lastName" required />
                            <div class="invalid-feedback">
                                Ingresa un apellido
                            </div>
                        </div>
                        <div class="col-md col-9">
                            <input type="email" class="form-control input" placeholder="Ingresa tu email" v-model.trim="form.email"
                                   required />
                            <div class="invalid-feedback">
                                Ingresa un email
                            </div>
                        </div>
                        <div class="col-md col-9">
                            <input type="phone" class="form-control input" placeholder="Ingresa tu tel&eacute;fono" v-model.trim="form.phone" maxlength="10"
                                   required />
                            <div class="invalid-feedback">
                                Ingresa un tel&eacute;fono
                            </div>
                        </div>
                        <div class="col-md-auto col-9">
                            <button type="submit" class="btn btn-red-blue fw-400 w-100 btn-lg">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="footer py-5">
        <div class="container">
            <div class="row gy-4">
                <div class="col-md-6 col-lg-5 col-xl-4">
                    <div class="row gy-4" id="footerNavigation">
                        <div class="col-12">
                            <span class="fw-bold">Exploraci&oacute;n</span>
                        </div>
                        <div class="col-lg-6 mt-0">
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'loreto' }">
                                        Inicio
                                    </router-link>
                                </li>
                                <li>
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                                       aria-expanded="false">
                                        Paquetes vacacionales
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li v-for="destination in websiteStore.destinations" :key="destination.slug">
                                            <router-link class="dropdown-item" :to="{
                        name: 'destination',
                        params: { slug: destination.slug },
                      }">
                                                {{ destination.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'reviews' }">
                                        Rese&ntilde;as
                                    </router-link>
                                </li>
                                <!-- <li>
                                  <router-link :to="{ name: 'reviews' }">
                                    Blog
                                  </router-link>
                                </li> -->
                                <li>
                                    <router-link :to="{ name: 'faqs-es' }">
                                        Preguntas frecuentes
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'vip' }" v-if="websiteStore.isVip">
                                        Promociones VIP
                                    </router-link>
                                    <span aria-current="page" data-bs-toggle="modal" data-bs-target="#vipModal" v-else>
                                        Promociones VIP
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 mt-0">
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'terminos-y-condiciones' }">
                                        T&eacute;rminos y condiciones
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'privacidad' }">
                                        Pol&iacute;tica de privacidad
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4">
                    <p><b>Direcci&oacute;n </b></p>
                    <p>Boulevard Francisco Medina Ascencio KM 2.5, Zona Hotelera Norte,Puerto Vallarta, Jalisco.</p>
                    <p><b>Cont&aacute;ctanos</b></p>
                    <a :href="'tel:+1' + websiteStore.getPhone">
                        <p>P: {{ websiteStore.getPhone }}</p>
                    </a>
                </div>
                <div class="col-lg-4 col-xl-4 pb-5 pb-lg-0">
                    <div class="row gy-2">
                        <div class="col-md-6 col-lg-12">
                            <div class="row gy-2">
                                <strong>Tarjetas de cr&eacute;dito que tomamos</strong>
                                <img class="col-auto" src="@/assets/icons/cc-mastercard.svg" />
                                <img class="col-auto" src="@/assets/icons/cc-visa.svg" />
                                <img class="col-auto" src="@/assets/icons/cc-amex.svg" />
                                <img class="col-auto" src="@/assets/icons/cc-discover.svg" />
                                <div class="col-12">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-12">
                            <div class="row gy-2">
                                <b>S&iacute;guenos </b>
                                <a class="navbar-brand col-auto" href="https://www.instagram.com/vacationdealsallinclusive/"
                                   target="_blank" rel="noopener noreferrer"><img src="@/assets/icons/instagram_icon.svg" /></a>
                                <a class="navbar-brand col-auto" href="https://www.facebook.com/VacationDealsAllInclusive/"
                                   target="_blank" rel="noopener noreferrer"><img src="@/assets/icons/facebook_icon.svg" /></a>
                                <!-- <a class="navbar-brand col-auto" href="https://www.tiktok.com/@vacationdealsvdai" target="_blank"
                                  rel="noopener noreferrer"><img src="@/assets/icons/Tik_tok_white.svg" width="30" /></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="fixed-bottom shadow-lg text-center row d-lg-none d-flex align-items-center">
        <div class="col-10 pt-3 pb-2 phone-number">
            <a :href="'tel:' + websiteStore.getPhone">
                <h3> Toll Free: {{ websiteStore.getPhone }}</h3>
            </a>
        </div>
        <div class="col-2 chat-icon ps-0 ">
            <button class="btn" @click="jivo_openChat()">
                <img src="@/assets/images/layout/chat_white.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script>
     import Swal from 'sweetalert2'

    import { registerLead } from "@/api/eplat";
    import { useWebsiteStore } from '@/store/website';

    export default {
        setup() {
            const websiteStore = useWebsiteStore();

            return { websiteStore };
        },
        data() {
            return {
                form: {
                    webhookID: "9dedfe6d-f256-b07a-211a-f1532abf5874",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: ""
                },
            }
        },
        methods: {
            jivo_openChat() {
                /*eslint no-undef: "error"*/
                // eslint-disable-next-line
                jivo_api.open()
            },
            async submitForm({ target }) {

                if (target.checkValidity()) {
                    this.loadingMessage();

                    await registerLead(this.form)
                        .then(() => {
                            Swal.close();
                            // console.log(res);
                            this.alertMessage()
                            this.tab = 1
                        })
                        .catch((error) => {
                            Swal.close();
                            console.log(error);
                            this.errorMessage()
                            this.tab = 0
                        }).finally(() => {
                            //Perform action in always
                        });


                } else target.classList.add('was-validated');

            },
            errorMessage() {
                return Swal.fire({
                    icon: "warning",
                    title: "Oops",
                    text: "Ocurri� un error inesperado, por favor vuelve a intentarlo.",
                    iconColor: "#ffd700",
                    showConfirmButton: false,
                    timer: 5000,
                    backdrop: `rgba(0,101,129,0.7)`,
                });
            },
            loadingMessage() {
                this.loading = Swal.mixin({
                    customClass: {
                        loader: 'spinner',
                    }
                })
                return this.loading.fire({
                    text: "Enviando",
                    showConfirmButton: false,
                    iconColor: "#0c9491",
                    backdrop: `rgba(0,101,129,0.7)`,
                    width: 160,
                    timerProgressBar: true,
                    // timer: 3000,
                    didOpen: () => {
                        this.loading.showLoading();
                    },
                });
            },
            alertMessage() {
                this.tab = 3
                return Swal.fire({
                    icon: "success",
                    title: "Gracias, esta todo listo.",
                    text: "Un agente se contactara pronto.",
                    color: "#0C9491",
                    showConfirmButton: false,
                    timer: 5000,
                    iconColor: "#0C9491",
                    backdrop: `rgba(0,101,129,0.7)`,
                });
            },
        }
    }
</script>

<style scoped>
    .footer {
        background-color: #0C9491;
        color: #ffff;
    }


    a {
        text-decoration: none;
        color: #ffff;
    }

    .footer-form {
        background-color: #3a2050;
        color: #ffff;
    }

    .input {
        border: 2px solid #0c9491;
        height: 48px;
    }

    li a {
        color: #0c9491
    }

    .fixed-bottom {
        background-color: #0c9491;
    }

    .nav-link {
        color: white;
        padding: 4px;
    }

    .chat-icon {
        background-color: #0c9491;
    }

    .phone-number {
        background-color: #0A6B69;
    }

    #footerNavigation ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    #footerNavigation > div > ul > li > a {
        color: white;
    }

    a {
        transition: ease 0.25s text-shadow;
    }

        a:not(.dropdown-item):hover {
            text-shadow: 5px 5px 5px #0f6160;
        }

    #footerNavigation > div > ul > li {
        padding-top: 0.5rem;
    }

    #footerNavigation > div > ul > li {
        padding-bottom: 0.5rem;
    }
</style>
