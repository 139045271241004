<template>
  <section v-if="show">
    <div class="p-0 m-0 col position-absolute"><img :src="getIcon.imgUrl" alt="" :class="classObject" class=""
        data-bs-dismiss="modalPrizeIcon" @click="showModal()"></div>
    <!-- Modal Wheel -->
    <div class="modal fade z-0 " :id="prize.code" tabindex="-1" aria-labelledby="modalPrizeIcon" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content random-icon-bg">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0 text-center bg-pink-gradient" style="--bs-bg-opacity: .5;">
            <div class="col-12 random-icon-title-bg pt-md-4 pb-md-3 title">
              <h1>congrats!</h1>
            </div>
            <img :src="prize.imgUrl" alt="" class="w-75 pt-md-4 pt-2 pb-md-3 pb-2 title">
            <h6 class="text-white">*Valid with the purchase of a Vacation Package to Mexico.</h6>
            <lotteryForm :prizeCode="prize.code" :prizeName="''" />
            <h1 class="title pt-3">to redeem your prize!</h1>
          </div>
        </div>
      </div>
    </div>
        <!-- Modal prize message -->
        <div class="modal fade z-0 " :id="'modalPrizeFinal' + prize.code" tabindex="-1" aria-labelledby="modalPrizeIcon" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content random-icon-bg">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0 text-center bg-pink-gradient" style="--bs-bg-opacity: .5;">
            <img src="../assets/images/campaigns/easter/banner-final.jpg" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import lotteryForm from "@/components/TheLotteryForm.vue"
import Confetti from 'canvas-confetti'
export default {
  props: [
    'type'
  ],
  data() {
    return {
      icon: {
        imgUrl: require('@/assets/images/campaigns/easter/egg-tester.gif'),
        classType: ''
      },
      prize: {
        type: '',
        imgUrl: '',
        code: '',
        name: ''
      },
      interval: '',
      hasPlayed: localStorage.getItem("easterPrize"),
      show: false
    }
  },
  components:
  {
    lotteryForm
  },
  methods: {
    setIconImageClass(type) {
      switch (type) {
        case 1:
          this.icon.classType = '1'
          break;
        case 2:
          this.icon.classType = '2'
          break;
        case 3:
          this.icon.classType = '3'
          break;
        case 4:
          this.icon.classType = '4'
          break;
        case 5:
          this.icon.classType = '5'
          break;
        case 6:
          this.icon.classType = '6'
          break;
        case 7:
          this.icon.classType = '7'
          break;
        default:
          break;
      }
    },
    setIconImageAndprize() {
      switch (this.prize.type) {
        case 1:
          this.icon.imgUrl = require('@/assets/images/campaigns/easter/egg-01.gif',)
          this.prize.code = 'VAL50DIS',
            this.prize.name = '$50 usd DISCOUNT',
              this.prize.imgUrl = require('@/assets/images/campaigns/easter/prizes/prize-01.png')
          break;
        case 2:
          this.icon.imgUrl = require('@/assets/images/campaigns/easter/egg-02.gif',)
          this.prize.code = 'VAL100DIS',
            this.prize.name = '$100 usd DISCOUNT',
              this.prize.imgUrl = require('@/assets/images/campaigns/easter/prizes/prize-02.png')
          break;
        case 3:
          this.icon.imgUrl = require('@/assets/images/campaigns/easter/egg-03.gif')
          this.prize.code = 'VALVEGAS',
            this.prize.name = 'FREE VACATION IN LAS VEGAS',
              this.prize.imgUrl = require('@/assets/images/campaigns/easter/prizes/prize-03.png')
          break;
        case 4:
          this.icon.imgUrl = require('@/assets/images/campaigns/easter/egg-04.gif',)
          this.prize.code = 'VAL75DIS',
            this.prize.name = '$75 usd DISCOUNT',
              this.prize.imgUrl = require('@/assets/images/campaigns/easter/prizes/prize-04.png')
          break;
        case 5:
          this.icon.imgUrl = require('@/assets/images/campaigns/easter/egg-05.gif',)
          this.prize.code = 'VALCRUISE',
            this.prize.name = 'FREE CRUISE',
              this.prize.imgUrl = require('@/assets/images/campaigns/easter/prizes/prize-05.png')
          break;
        default:
          break;
      }
    },
    showModal() {
      clearInterval(this.interval);
      if (this.hasPlayed) {
      let bannerModalS = document.querySelector('#modalPrizeFinal'+this.prize.code);
      //eslint-disable-next-line
      const bannerModalInstance = new bootstrap.Modal(bannerModalS);
      bannerModalInstance.show();
      console.log('played')
        
      } else {
        
      let bannerModal = document.querySelector('#' + this.prize.code);
      //eslint-disable-next-line
      const bannerModalInstance = new bootstrap.Modal(bannerModal);
      bannerModalInstance.show();
      }


      this.getConfetti()
    },
    closeModal() {
      //eslint-disable-next-line
      var pagebutton = document.getElementById("closeModal");
      pagebutton.click();
    },
    getConfetti() {
      Confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 2000,
        shapes: ['circle'],
      })
    }
  },
  computed: {
    classObject() {
      return {
        'egg-01': this.icon.classType == '1',
        'egg-02': this.icon.classType == '2',
        'egg-03': this.icon.classType == '3',
        'egg-04': this.icon.classType == '4',
        'egg-05': this.icon.classType == '5',
        'egg-06': this.icon.classType == '6',
        'egg-07': this.icon.classType == '7',
      }
    },
    getIcon() {
      this.setIconImageAndprize()
      return this.icon
    }
  },
  created() {
    this.prize.type = Math.floor(Math.random() * 5) + 1;
    this.interval = setInterval(() => {
      this.prize.type = Math.floor(Math.random() * 5) + 1; // Set variable to a random number between 0 and 99
    }, 5000); // 5000 milliseconds = 5 seconds
    console.log(this.interval)
  },
  mounted() {
    // this.setIconImageClass(this.type)
    // this.showModal()
  }
}
</script>

<style>
.egg-01 {
  transform: rotate(0deg);
  width: 80px;
  left: 95px;
  position: relative;
  bottom: 68px;
}

.egg-02 {
  /* transform: rotate(-20deg); */
  position: relative;
  width: 51px;
  bottom: 19px;
  left: 500%;
}

.egg-03 {
  /* transform: rotate(30deg); */
  width: 40%;
  position: relative;
  bottom: 61px;
  right: 81px;
}

.egg-04 {
  /* transform: rotate(-30deg); */
  position: relative;
  width: 41%;
  top: -13px;
  left: 314px;
}

.egg-05 {
  /* transform: rotate(20deg); */
  position: relative;
  width: 43%;
  bottom: 65px;
  left: 217px;
}

.egg-06 {
  transform: rotate(0deg);
  width: 44%;
  left: 269px;
  position: relative;
  bottom: 66px;
  display: block;
}

h1 {
  font-size: 45px !important;
}

.random-icon-bg {
  background: rgb(70, 157, 184);
  background: linear-gradient(330deg, rgba(70, 157, 184, 1) 61%, rgba(159, 218, 234, 1) 100%);
}

.random-icon-title-bg {
  background: rgb(141, 92, 171);
  background: linear-gradient(330deg, rgba(141, 92, 171, 1) 61%, rgba(201, 165, 227, 1) 100%);
}

.title {
  font-family: Bebas Neue;
  color: white;
}

.modal-backdrop.fade.show {
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .egg-07 {
    transform: rotate(0deg);
    width: 44%;
    left: 269px;
    position: relative;
    bottom: 66px;
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .egg-07 {
    transform: rotate(0deg);
    width: 44%;
    left: 269px;
    position: relative;
    bottom: 66px;
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .egg-07 {
    transform: rotate(0deg);
    width: 44%;
    left: 269px;
    position: relative;
    bottom: 66px;
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .egg-01 {
    transform: rotate(0deg);
    width: 133px;
    left: 95px;
    position: relative;
    bottom: 112px;
  }

  .egg-02 {
    /* transform: rotate(-20deg); */
    position: relative;
    width: 96px;
    bottom: 55px;
    left: 781%;
  }

  .egg-03 {
    /* transform: rotate(30deg); */
    width: 70%;
    position: relative;
    bottom: 104px;
    right: -237px;
  }

  .egg-04 {
    /* transform: rotate(-30deg); */
    position: relative;
    width: 45%;
    top: -19px;
    /* left: 314px; */
  }

  .egg-07 {
    transform: rotate(0deg);
    width: 60%;
    left: 269px;
    position: relative;
    bottom: 165px;
    display: block;
  }

}
</style>