<template>
    <the-details />
    <div class="container">
        <div class="row px-4">
            <div class="col-12 col-lg-6">
                <div class="row">
                    <h5 class="fw-bold pb-3">Why Get This Luxury Vacation Package?</h5>
                    <div class="col-1 text-end">
                        <img src="../../assets/icons/check_circle.svg" alt="">
                    </div>
                    <div class="col">
                        <p> <span class="fw-bold">5 Days and 4 Nights</span> at our Luxury Oceanfront Resort in Cancun, Mexico.</p>
                    </div>
                    <hr class="shuted-line">
                    <div class="col-1 text-end">
                        <img src="../../assets/icons/check_circle.svg" alt="">
                    </div>
                    <div class="col">
                        <p><span class="fw-bold">All water sports</span> and nonmotorized activities are also <span class="fw-bold">included</span> in your reservation.</p>
                    </div>
                    <hr class="shuted-line">
                    <div class="col-1 text-end">
                        <img src="../../assets/icons/check_circle.svg" alt="">
                    </div>
                    <div class="col">
                        <p><span class="fw-bold">All-Inclusive:</span> With unlimited à la carte and buffet-style dining, a wide variety of cocktails
                            and international beverages, nightly entertainment, and daily entertainment.</p>
                    </div>
                    <hr class="shuted-line">
                    <div class="col-12 pb-lg-5 pt-3">
                        <div class="col-12 col-lg-5 border border-black rounded justify-content-center align-items-center p-3 d-none d-lg-flex">
                            <img src="../../assets/icons/paid.svg" alt="">
                            <h5 class="fw-bold text-black p-0 m-0">Save Over 70%!*</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row">
                    <h5 class="fw-bold pb-3">Why You Get It</h5>
                    <div class="col-1 text-end">
                        <img src="../../assets/icons/check_circle.svg" alt="">
                    </div>
                    <div class="col">
                        <p>Investing just 90 minutes of your time in this presentation is not just a requirement, but an
                            opportunity to get the discount you deserve. Normally, this 5-day, 4-night vacation package
                            is <span class="fw-bold">valued at $1679 USD</span>, but by attending the presentation, <span class="fw-bold">
                                you and your partner will only
                                pay $499 USD.
                            </span>

                            <br><br>*Attending this presentation tour is an obligatory requierment
                        </p>
                    </div>
                    <hr class="shuted-line">
                    <div class="col-1 text-end">
                        <img src="../../assets/icons/check_circle.svg" alt="">
                    </div>
                    <div class="col">
                        <p>This is an exclusive offer for those who value their time and know they deserve the best.
                            Don’t miss this chance to enjoy a dream vacation at an incredible price.</p>
                    </div>
                    <hr class="shuted-line">
                    <div class="col-12 pb-4 pt-2">
                        <div
                            class="col-12 col-lg-5 border border-black rounded d-flex justify-content-center align-items-center p-3 d-lg-none">
                            <img src="../../assets/icons/paid.svg" alt="">
                            <h5 class="fw-bold text-black p-0 m-0">Save Over 70%!*</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheDetails from "@/components/landing-page/TheDetailsGallery.vue"
export default {
    components:
    {
        TheDetails
    },
}
</script>

<style scoped>
h5 {
    color: #0C9491;
}

hr.shuted-line {
    border-top: 1px dashed;
}
</style>