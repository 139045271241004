<template>
    <div class="fixed-bottom d-none col-lg-4 col-xl-3" id="fixedMenu">
        <div class="row">
            <div class="col-lg-12 fixed-menu p-4 m-lg-5" id="fixedMenuAlert">
                <div class="col-12 timer-box pb-3">
                    Claim Your Offer Now!
                    <div class="text-white rounded-pill timer py-1 px-2">
                        <img src="@/assets/icons/timer.svg" alt=""> <vue-countdown :time="2700000"
                            v-slot="{ minutes, seconds }">
                            {{ minutes }}:{{ seconds }}
                        </vue-countdown>
                    </div>
                </div>
                <div class="col">
                    <div class="btn form-control btn-next p-3" @click='scrollToTop()'> I want this deal <img
                            src="@/assets/icons/arrow_right.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    components: {
        VueCountdown
    },
    mounted() {
        let lastScrollPosition = 0;
        let fixedMenu = document.querySelector('#fixedMenu');
        let fixedMenuAlert = document.querySelector('#fixedMenuAlert');


        window.addEventListener('scroll', function (e) {// eslint-disable-line
            lastScrollPosition = window.scrollY;
            if (lastScrollPosition < 100) {
                fixedMenuAlert.classList.add('fade-out')
                fixedMenuAlert.classList.remove('fade-in')
                // fixedMenu.classList.add('d-none')

            } else {
                fixedMenuAlert.classList.remove('fade-out')
                fixedMenuAlert.classList.add('fade-in')
                fixedMenu.classList.remove('d-none')

            }
        });
    },
    methods: {
        scrollToTop(){
            document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }
}
</script>

<style scoped>
.timer {
    font-size: 12px;
    color: #ffff;
    background-color: #d50758;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
}

.timer-box {
    display: flex;
    justify-content: space-between;
}

.fixed-menu {
    background-color: #efefef;
    box-shadow: 2px 10px 14px 0px rgba(112, 112, 112, 0.75);
    -webkit-box-shadow: 2px 10px 14px 0px rgba(112, 112, 112, 0.75);
    -moz-box-shadow: 2px 10px 14px 0px rgba(112, 112, 112, 0.75);
}

.fade-out {
    animation: fadeOutAnimation ease-in-out .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

}

.fade-in {
    animation: fadeInAnimation ease-in-out .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.btn-next:hover {
color: #fff;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOutAnimation {
    0% {
        
        opacity: 1;
        
    }

    100% {
        opacity: 0;
        pointer-events: none;
    }
}

@media (min-width: 992px) { 
    .fixed-menu {
        border-radius: .5rem;
}
 }
</style>