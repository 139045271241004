import axios from 'axios';

const ePlatApi = axios.create({
    baseURL: 'https://hooks.zapier.com/hooks/catch/4763102' 
    // baseURL: 'https://localhost:44308/api'
});

//Registra un lead en el webhook indicado en el objecto formData
export const registerLead = async (formData = {}) => {
    return new Promise((resolve, reject) => {
        ePlatApi.post(`/3n3997p`,  JSON.stringify(formData))
            .then(({ data }) => resolve(data))
            .catch(reject);
    });
}