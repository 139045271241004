<template>
    <swiper ds :modules="modules" class="mySwiper" v-bind="swiperOptions">
      <swiper-slide v-for="review in websiteStore.reviewsCollection.body.feeds" :key="review.feedId" class="mb-lg-3 shadow">
        <div class="row text-center">
          <div class="col-lg">
            <div class=" text-center bg-white p-3 review-card h-100">
              <img :src="review.postUserPicture" alt="" class="rounded-circle mb-4 mt-3" width="70" height="70">
              <h5>{{ review.postAuthorName }}</h5>
              <span v-for="(n, index) in 5 " :key="index"
                class="material-icons-outlined stars me-1 s-0 p-0 mb-4 h-auto">
                star
              </span>
              <p>
                {{ review.postContent }}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="text-center">
      <router-link :to="{ name: 'reviews' }">
        <button class="btn all-reviews p-3 mt-3">CHECK ALL REVIEWS</button>
      </router-link>
    </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import { useWebsiteStore } from '@/store/website';

export default {
  data() {
    return {
      recaptchaScript: null,
      reviews: [],
      swiperOptions:{
        pagination: {
          dynamicBullets: true,
        },
        slidesPerView: 1,
        spaceBetween: 16,
        breakpoints: {
          768: {
            slidesPerView: 3,
            loop: false,
          }
        }
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const websiteStore = useWebsiteStore();

    return {
      websiteStore,
      modules: [Navigation, Pagination],
    };
  },
  methods: {
    getReviews() {
      axios
        .get("https://web.tagembed.com/app/api/wall/62004")
        .then(({ data }) => {
          // console.log(res.data.data[0]);
          this.reviews = Object.values(data.postData);
          // this.reviews = res.data.postData.slice(0,1)

          // this.alertMessage()
        })
        .catch((error) => {
          console.log(error);
          // this.errorMessage();
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  mounted() {
    this.getReviews();
  },
};
</script>
  
<style scoped>
.review-card h5 {
  font-weight: 800;
  font-size: 14px;
}

.review-card .stars {
  color: #0C9491;
}

.review-card p {
  font-style: italic;
}

.review-date {
  font-size: 14px;
  font-style: italic;
}

.all-reviews {
  border: solid 2px #0A6B69;
  color: #0A6B69;
  font-size: 12px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-left: 10%;
  padding-left: 7% !important;
  padding-right: 7% !important;
}

.all-reviews:hover {
  background-color: #0A6B69;
  color: #ffffff;
}

.container p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  position: sticky;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  margin-top: 15px;
  bottom: -35px;
  left: 0;
  width: 100%;
}
</style>>

