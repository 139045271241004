<template>
    <div class="container-fluid bg--confirmation px-0">
        <div class="bg--confirmation__shadow h-100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center py-3">
                        <img src="../../assets/images/logo.png" alt="">
                    </div>
                    <div class="col col-xl-5 col-lg-6 col-md-8 px-4">
                        <div class="container bg-white rounded-1 p-3 d-grid pb-1">
                            <h2>You’re All Set!</h2>
                            <span class="text__description mb-3">5 Days & 4 Nights at a Luxury Cancun Resort</span>
                            <span class="text__price mb-2">$499<sup class="text__price--currency">USD</sup></span>
                            <h2>Per 2 adults </h2>
                            <table class="table lh-base mb-0">
                                <tbody>
                                    <tr class="border-top">
                                        <th scope="row">Destination:</th>
                                        <td>Cancun</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Resort:</th>
                                        <td>Luxury Mexico Resort</td>

                                    </tr>
                                    <tr>
                                        <th scope="row">Days & Nights:</th>
                                        <td>5 Days & 4 Nights</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Room Type:</th>
                                        <td>Deluxe Suite or Studio Suite</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total Paid:</th>
                                        <td><span class="text-decoration-line-through">$1,679</span> <span class="fw-bold">$499</span> per 2 adults</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="border-bottom-0 text__terms">
                                            <span class="fw-bold">Terms:</span> Our Resort All-Inclusive program covers,
                                            unlimited meals, unlimited
                                            beverages, daily activities, nightly entertainment themed shows.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 px-4 ">
                        <div class="container-fluid  rounded-1 p-3 pb-0">
                            <div class="row d-flex justify-content-center pb-3">
                                <div class="col col-lg-4 col-md-8 d-flex justify-content-center">
                                    <img src="../../assets/images/agent-pic.png" alt="" class="rounded-circle" width="70">
                                    <p class="m-0 p-0 text__agent d-flex align-items-center ps-3">A vacation concierge
                                        will be in touch with you soon to book your dates and give you all the details!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-5 col-lg-6 px-4">
                        
                    <div class="col terms__footer rounded-2 p-3 px-3 fixed-bottom">
                        Call now to save your dates
                        <div class="col btn-next p-3 mt-2">
                            <span class="fw-bold">Call Now!</span> <img src="../../assets/icons/phone.svg" alt="">
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.bg--confirmation {
    background-image: url(../../assets/images/backgrounds/bg-confirmation.jpg);
    height: 100vh;
    background-size: cover;
}

.bg--confirmation__shadow {
    background-color: rgba(0, 0, 0, .4) !important;
}

.table {
    font-size: 14px !important;
}

th {
    font-weight: bold;
    color: #525252;
}

h2 {
    font-weight: bold;
    color: #0C9491;
}

.text__description {
    font-size: 14px;
}

.text__price {
    color: #0C9491;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 74px;
    /* 115.625% */
}

.text__price--currency {
    font-weight: 800;
}

.text__terms {
    font-size: 12px;
}

.text__agent {
    font-size: 12px;
    font-weight: 600;
    color: #ffff;
}

.terms__footer {
    background-color: #EFEFEF;
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
}

sup {
    top: -1.5rem!important;
    font-size: 32px;
}

@media (min-width: 992px) {
    .terms__footer {
    background-color: #EFEFEF;
position: relative;
border-bottom-right-radius:0.375rem!important;
border-bottom-left-radius: 0.375rem!important;
}
}
</style>