export const getWeatherIcon = (description) => {
    let iconUrl;

    switch (description) {
        case "clear sky":
            iconUrl = require('@/assets/images/weather/icons/sunny.svg');
            break;
        case "few clouds":
            iconUrl = require('@/assets/images/weather/icons/few-clouds.svg');
            break;
        case "scattered clouds":
            iconUrl = require('@/assets/images/weather/icons/scattered-clouds.svg');
            break;
        case "broken clouds":
            iconUrl = require('@/assets/images/weather/icons/broken-clouds.svg');
            break;
        case "shower rain":
            iconUrl = require('@/assets/images/weather/icons/shower-rain.svg');
            break;
        case "rain":
            iconUrl = require('@/assets/images/weather/icons/rain.svg');
            break;
        case "thunderstorm":
            iconUrl = require('@/assets/images/weather/icons/thunderstorm.svg');
            break;
        case "snow":
            iconUrl = require('@/assets/images/weather/icons/snow.svg');
            break;
        case "mist":
            iconUrl = require('@/assets/images/weather/icons/mist.svg');
            break;
        default: iconUrl = require('@/assets/images/weather/icons/mist.svg');
            break;
    }

    return iconUrl
}

export const getWeatherImage = (description) => {
    let iconUrl;

    switch (description) {
        case "clear sky":
            iconUrl = require('@/assets/images/weather/sunny.png');
            break;
        case "few clouds":
            iconUrl = require('@/assets/images/weather/few-clouds.png');
            break;
        case "scattered clouds":
            iconUrl = require('@/assets/images/weather/scattered-clouds.png');
            break;
        case "broken clouds":
            iconUrl = require('@/assets/images/weather/broken-clouds.png');
            break;
        case "shower rain":
            iconUrl = require('@/assets/images/weather/shower-rain.png');
            break;
        case "rain":
            iconUrl = require('@/assets/images/weather/rain.png');
            break;
        case "thunderstorm":
            iconUrl = require('@/assets/images/weather/thunderstorm.png');
            break;
        case "snow":
            iconUrl = require('@/assets/images/weather/snow.png');
            break;
        case "mist":
            iconUrl = require('@/assets/images/weather/mist.png');
            break;
        default: iconUrl = require('@/assets/images/weather/mist.png');
            break;
    }
    return iconUrl
}

export const getTime = (date) => {
    const newDate = new Date(date * 1000);

    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    let meridian = 'AM';

    if (hours > 11 && hours < 23) {
        meridian = 'PM';

        if (hours > 12) hours = hours - 12;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`
    }
    return `${hours}:${minutes} ${meridian}`;
}